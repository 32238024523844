
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {slide as Menu} from 'react-burger-menu';
import "./styles/menuCentralMovil.css"

class MenuCentralMovil extends Component {

  constructor(){
    super();
    this.state={
      selected: 0,
      menuOpen:false,
      menu:false
    }
    this.show = this.show.bind(this);
  }

  /*onSelect = key => {
    this.setState({selected:key})
  }*/



  show(){
    alert("hola");
    this.setState({menu:true})
  }

  render () {
    return (
      <div>



      <Menu isOpen={this.state.menu} width={ 200 }>
        <Link className="menu-item" to="/ofertaAcademica">
          OFERTA ACADÉMICA
        </Link>

        <Link className="menu-item" to="/campus">
          RED DE CAMPUS EN JALISCO
        </Link>

        <a href="http://controlescolar.tecmm.edu.mx/?#/">
          SERVICIOS ESCOLARES
        </a>

        <Link className="menu-item" to="/quienesSomos">
          QUIÉNES SOMOS
        </Link>

        <Link className="menu-item" to="/Rectoria">
          RECTORIA
        </Link>

        <Link className="menu-item" to="/Bienvenida">
          BIENVENIDA
        </Link>

        <Link className="menu-item" to="/modeloAcademicoMovil">
          MODELO ACADEMICO
        </Link>

        <a className="menu-item" href="http://controlescolar.tecmm.edu.mx">
          ADMISIÓN
        </a>

        <Link className="menu-item" to="/vidaEstudiantil">
          VIDA ESTUDIANTIL
        </Link>

        <a className="menu-item" href="http://vinculacion.tecmm.edu.mx/">
          VINCULACIÓN
        </a>

        <a href="http://www.areaacademica.tecmm.edu.mx/">
          ÁREA ACADÉMICA
        </a>

        <Link to={'/Igualdad'}>
          SGIG
        </Link>

        <Link to={'/Contacto'}>
          CONTACTO
        </Link>

        <Link to={'/quejasYdenuncias'}>
          QUEJAS Y DENUNCIAS
        </Link>

      </Menu>

      </div>
    );
  }
}

export default MenuCentralMovil
