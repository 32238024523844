import React, { Component } from 'react';
import Add from '@material-ui/icons/AddCircle';

import Typist from 'react-typist';
import ReactImageMagnify from 'react-image-magnify';
import {withGetScreen} from 'react-getscreen'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



import Rodal from 'rodal';

import 'rodal/lib/rodal.css';

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'
import bannerTNM from '../header-nuevo2.jpg'


import './styles/convocatorias.css'


class BecasItem extends Component{

  constructor(props){
    super(props);
    this.state={
      visible: false
    }
  }



  onClickMas=()=>{
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render(){
    return(
      <div class="card">
        <div class="image">
          <img src={this.props.image}/>
        </div>
        <div class="content">
          <div class="header"><a style={{color:"#6D356C"}} href={this.props.link}>{this.props.nombre}</a></div>

          <div class="description">
            {ReactHtmlParser(this.props.infoCard)}

          </div>
        </div>
        <div class="extra content">
          <span class="right floated">
            {this.props.fecha}
          </span>

        </div>
      </div>
    );
  }
}


class Convocatorias extends Component {

  constructor(){
    super();
    this.state={

      becasArray:[

        {nombre: '"Apoyo por haber concluido la Titulación"', fecha:'13 al 27 de septiembre de 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/manutencion.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/manutencion.pdf', infoCard:'La Secretaría de Educación Pública (SEP), a través de la Subsecretaría de Educación Superior (SES) convoca a estudiantes mexicanos/as inscritos/as en el nivel de Licenciatura, Licencia Profesional o Técnico Superior Universitario (TSU) en Instituciones Públicas de Educación Superior (IPES) en el país, a postularse para la Beca de Manutención Federal para la Educación Suprior 2019-II, cuyo objetivo es fomentar que tengan acceso, continúen y concluyan oportunamente con sus estudios en el tipo superior, evitando así la deserción escolar.'},

        {nombre: '"Apoyo por haber concluido la Titulación"', fecha:'30 de septiembre a 10 de octubre de 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/conclucion-titulacion.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/conclusion-titulacion.pdf', infoCard:'La Secretaría de Educación Pública (SEP), a través de la Subsecretaría de Educación Superior (SES) convoca A egresados/as de Licenciatura y Técnico Superior Universitario (TSU) de Instituciones Públicas de Educación Superior (IPES) del país, a solicitar una beca o apoyo por haber concluido la Titulación mediante la presentación de una tesis, o cualquier otro trabajo escrito profesional con el que se obtuvo el grado académico.'},

        {nombre: '"Beca para iniciar la Titulación"', fecha:'30 de septiembre a 10 de octubre de 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/inicio-titulacion.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/inicio-titulacion.pdf', infoCard:'La Secretaría de Educación Pública (SEP), a través de la Subsecretaría de Educación Superior (SES) convoca a estudiantes y egresados/as de Licenciatura y Técnico Superior Universitario (TSU) de Instituciones Públicas de Educación Superior (IPES) del país, a solicitar una beca o apoyo para iniciar el trámite de una tesis, o cualquier otro trabajo escrito profesional que los conduzca a la titulación.'},

        {nombre: 'Reto “Mundo Sin Residuos 2019-2010”', fecha:'Revisar Convocatoria', image:'http://tecmm.edu.mx/convocatorias(PDF)/Reto-Mundo-Sin-Residuos.JPG', link:'http://tecmm.edu.mx/convocatorias(PDF)/Reto-Mundo-Sin-Residuos.pdf', infoCard:'The Coca Cola Company invita a particar en su Reto “Mundo Sin Residuos 2019-2010” como parte de su compromiso y de su estrategia mundial World Without Waste; con el objetivo de lograr en el 2030 recolectar y reciclar el equivalente al 100% de los envases que comercializa.'},

        {nombre: '“XXVI Concurso Nacional Intertecnológicos de Estudiantes de Arquitectura”', fecha:'Fecha límite: 14 Octubre 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/intertec-arquitectura.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/intertec-arquitectura.zip', infoCard:'El Concurso Nacional Intertecnológicos de Estudiantes de Arquitectura, en su vigésima sexta edición, se reafirma como el evento de competencia académica entre estudiantes de Arquitectura de los Institutos Tecnológicos del país.'},


        {nombre: '“Programa de Movilidad Internacional Estudiantil”', fecha:'Fecha límite: 15 de diciembre de 2019 ', image:'http://tecmm.edu.mx/convocatorias(PDF)/beca-movilidad.jpg', link:'http://tecmm.edu.mx/convocatorias(PDF)/movilidad-estudiantil.pdf', infoCard:'TecMM invita a los estudiantes de nivel licenciatura, a participar a través de la gestión de financiamiento de apoyos para la realización de estancias académicas en instituciones de educación superior internacional.<br/><br/>Beneficios: El monto otorgado para movilidad internacional a países de habla hispana, sera de $30,000.00 M.N. y para países anglosajones (Europa, Estados Unidos y Canadá) será de $50,000.00 M.N.'},


        {nombre: '“Capital Robot.IA”', fecha:'30 y 31 de Octubre 2019 HASTA AGOTAR ENTRADAS', image:'http://tecmm.edu.mx/convocatorias(PDF)/beca-robot-ia.jpg', link:'https://capitalrobotia.com.mx/', infoCard:'El mayor evento de robótica del año, con la mejor preparación para el mundial RoboRAVE México 2021.<br/> Retos: Sumobot, Fire Figthting, Robot Arm, 7 to Smoke Sumobot y Elevator Pitch Robot. Premio: Viaje al mundial Japón 2020.<br/><br/>CIUDAD CREATIVA DIGITAL Guadalajara, Jal. México. '},


        {nombre: '“Convocatoria Santander Universities Academic Entreprenurs: A Program by Babson College”', fecha:'Fecha límite: 15 de Septiembre de 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/beca-babson.jpg', link:'https://santanderx.com/calls/santander-universities-academic-entrepreneurs-a-program-by-babson-college/5ce6b2236c0ebc0013881726?detail=true', infoCard:'Este programa se llevará a cabo del 03 al 08 de noviembre de 2019 en el Babson College de Massachusetts, Estados Unidos, que tiene como objetivo capacitar a los líderes educativos y generar en ellos un pensamiento crítico en cuanto a temas de emprendimiento, capaz de potenciar el desarrollo e impacto de los programas de enseñanza y aprendizaje en el ecosistema universitario emprendedor. '},


        {nombre: '“Convocatoria de becas Chevening para mexicanos, estudia en Reino Unido”', fecha:'Fecha límite: 05 de Noviembre de 2019  ', image:'http://tecmm.edu.mx/convocatorias(PDF)/beca-reino-unido.jpg', link:' http://becasparatodos.com/convocatoria-becas-chevening-para-mexicanos-reino-unido/', infoCard:'El programa ofrece a los futuros líderes mexicanos la oportunidad de estudiar en ese reino europeo, una maestría en cualquier área del conocimiento durante un (1) año. Chevening ofrece becas financiadas en su totalidad, lo que le permite a los beneficiarios del programa, enfocarse en sus metas profesionales y disfrutar la experiencia de estudiar en el extranjero.'},

        {nombre: '“Programa de becas de cooperación horizontal para países miembros de la Alianza del Pacífico Convocatoria 2020.”', fecha:'Fecha límite: 20 de septiembre de 2019  ', image:'http://tecmm.edu.mx/convocatorias(PDF)/beca-argentina.jpg', link:'http://tecmm.edu.mx/convocatorias(PDF)/beca-argentina.pdf', infoCard:'Programa para  Maestría, especialización y estancias de investigación en el marco de estudios doctorales o posdoctorales, en Universidades Nacionales de la República Argentina.<br/> Oferta académica Todas las áreas del conocimiento exceptuando posgrados en medicina, odontología, contaduría, publicidad, administración de empresas y psicoanálisis'},


        {nombre: '“Programa de becas de cooperación horizontal para países miembros de la Alianza del Pacífico Convocatoria 2020.”', fecha:'Fecha límite: 23 de septiembre 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/BECA-CHILE.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/beca-chile.pdf', infoCard:'  El programa académico debe estar dentro de las Maestrías acreditadas en universidades u otras instituciones de educación superior chilenas.'},

        {nombre: '“Beca Fulbright-García Robles de Negocios Binacionales en Estados Unidos”', fecha:'Fecha límite: 09 de enero de 2020', image:'http://tecmm.edu.mx/convocatorias(PDF)/BECA-FULL-BRIGHT.png', link:'http://comexus.org.mx/negocios_binacionales.php?fbclid=IwAR2dwZ9nPezMzXhrzilfTHQsZi9jv1xTHssesydDpo8xlk5FzVnwW0ZdPTc', infoCard:'Es una oportunidad para jóvenes mexicanos recién egresados de licenciatura o maestría, o para profesionistas que están iniciando su carrera, de realizar una pasantía en una empresa y de estudiar hasta cuatro cursos de nivel posgrado en una universidad de prestigio durante 10 meses en Estados Unidos'},


        {nombre: '“PLATAFORMA DE MOVILIDAD ESTUDIANTIL Y ACADÉMICA DE LA ALIANZA DEL PACÍFICO 2020”', fecha:'', image:'http://tecmm.edu.mx/convocatorias(PDF)/CONVOCATORIA-ALIANZA-DEL-PACIFICO-2020.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/CONVOCATORIA-ALIANZA-DEL-PACIFICO-2020.pdf', infoCard:'-Estudiantes de Pregrado de Institutos de Educación Superior, en modalidades de carreras técnicas y tecnológicas.<br/> -Estudiantes de Pregrado en Instituciones de Educación Superior en modalidad profesional.<br/>-Estudiantes de Doctorado<br/>-Profesores/as universitarios/as invitados/as<br/>-Investigadores/as'},


        {nombre: '“Premio Estatal de Innovación, Ciencia y Tecnología Jalisco 2019”', fecha:'26 de septiembre de 2019, hasta las 13:00 horas.', image:'http://tecmm.edu.mx/convocatorias(PDF)/premio-estatal-innovacion-ciencia-tecnologia.png', link:'http://tecmm.edu.mx/convocatorias(PDF)/Premio%20Estatal%20de%20Innovaci%c3%b3n.zip', infoCard:'Se convoca a estudiantes, científicos, empresarios, tecnólogos y público en general; que de manera individual o en equipo, hayan realizado y concluido proyectos de investigación y/o desarrollo tecnológico e innovación en el periodo comprendido entre el 01 de enero 2016 y el 16 de agosto de 2019.'},

        {nombre: '"Programa jalisciense de fomento a la propiedad intelectual (PROPIN) 2019"', fecha:'Fecha límite: 11 de septiembre de 2019', image:'http://tecmm.edu.mx/convocatorias(PDF)/programa-jalisciense-de-fomento-a-la-propiedad-intelectual-(PROPIN)-2019.jpeg', link:'http://www.coecytjal.org.mx/Plataforma/app/index.html#/detalles', infoCard:'Objetivo: Fomentar la protección de la propiedad Intelectual mediante el apoyo técnico y económico para la presentación de solicitudes de invenciones tales como:<br/>a. Registro de Patente.<br/>b. Registro de Modelo de Utilidad.<br/>c. Registro de Diseño Industrial.<br/>d. Registro de PCT (Tratado de Cooperación en Materia de Patentes).<br/>e. Registro de Software.<br/><br/>contacto: <a href="mailto:larisa.cruz@jalisco.gob.mx">larisa.cruz@jalisco.gob.mx</a>'},
      ]
    }
  }

  render() {


    return (

      <div className="div-principalConvocatorias">

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

        ) : (
        <div>
          <a href="/">
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          </a>
          <MenuCentral/>
        </div>
      )}

          <h1 style={{color:"#2DA19A", fontFamily:"Gotham-Ultra", fontSize:"4vh"}}>CONVOCATORIAS</h1>


          <div class="ui link cards">
            {this.state.becasArray.map((it)=>(
              <BecasItem nombre={it.nombre} fecha={it.fecha} image={it.image} link={it.link} infoCard={it.infoCard}/>
            ))}
          </div>

        {/*<div className="div-contenidoBecas">

        </div>*/}

        <LinksFooter/>

      </div>
    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Convocatorias, options);
