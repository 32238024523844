import React, { Component } from 'react';
import {withGetScreen} from 'react-getscreen'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'

import './styles/contraloriaSocial.css'






class ContraloriaSocial extends Component{

    constructor(props){
      super(props);
      this.state={
        carrerasArray:null,
      }

    }






  render(){
    return(
      <div className="div-principal-contraloriaSocial">

      {this.props.isMobile() || this.props.isTablet()?(
      <div>
        <MenuCentralMovil/>
        <img className="img-contraloriaSocial-bannerTNM" src={"http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"}/>
        <img className="img-nombreTECMM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
      </div>

      ) : (
      <div>
        <a href="/">
          <img className="img-contraloriaSocial-bannerTNM" src={"http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"}/>
        </a>
        <MenuCentral/>
      </div>
      )}


      <div className="div-contraloriaSocial">

        <img src="http://tecmm.edu.mx/imagesReact/images/contraloriaSocial/BotonContraloria.jpg"/>

        <div className="div-documentos">

          <div className="div-documento">
            <form action="http://tecmm.edu.mx/documentos/contraloriaSocial/Guia-Contraloria-Social.pdf">
              <button className="button-enlaces">GUÍA OPERATIVA DE CONTRALORÍA SOCIAL</button>
            </form>
          </div>

          <div className="div-documento">
            <form action="http://tecmm.edu.mx/documentos/contraloriaSocial/formato-quejas,denuncias,sugerencias-contraloria-social.pdf">
              <button className="button-enlaces">QUEJAS, DENUNCIAS O PETICIONES</button>
            </form>
          </div>

          <div className="div-documento">
            <form action="https://dpii.acad-tecnm.mx/prodep/">
              <button className="button-enlaces">ENLACE TECNM</button>
            </form>
          </div>



        </div>



        <div className="div-pregunta">
          <h1>¿Qué es el Programa PRODEP?</h1>
          <p>Es el Programa para el Desarrollo Profesional Docente (PRODEP), que tiene como objetivo contribuir para que el personal docente y personal con funciones de dirección, de supervisión, de asesoría técnico pedagógica y cuerpos académicos accedan y/o concluyan programas de formación, actualización académica, capacitación y/o proyectos de investigación para fortalecer el perfil necesario para el desempeño de sus funciones.</p>
        </div>

        <div className="div-pregunta">
          <h1>Características generales de los apoyos (tipo, monto, periodo de ejecución y fecha de entrega).</h1>
          <p>Con recursos económicos:
            <ul>
              <li>Incorporación de Nuevos Profesores de Tiempo Completo</li>
              <li>Reincorporación de Exbecarios PRODEP</li>
              <li>Profesores de Tiempo Completo con Perfil Deseable</li>
              <li>Estudios de Posgrados de Alta Calidad a nivel nacional.</li>
              <li>Estancias Cortas de Investigación (Individual o por Cuerpo Académico)</li>
              <li>Postdoctorado para Cuerpos Académicos</li>
              <li>Gasto de Publicación</li>
            </ul>
          </p>

          <p>Sin recursos:
            <ul>
              <li>Reconocimiento a Profesores de Tiempo Completo a Perfil Deseable</li>
              <li>Reconocimiento de Formación de Cuerpos Académicos</li>
              <li>Por mencionar algunos.</li>
            </ul>
          </p>
        </div>

        <div className="div-pregunta">
          <h1>Requisitos para la entrega de apoyos conforme a los establecidos en las convocatorias emitidas por PRODEP.</h1>
          <p>
            <ul>
              <li>Haber participados en las convocatorias del Programa antes mencionado, sin importar el género, si usted cumple con los requisitos y en la evaluación de la solicitud obtiene una ponderación aprobatoria, puede acceder al otorgamiento de apoyos con recurso.</li>
              <li>Haber obtenido recursos del Programa en cualquier Convocatoria que emite la Dirección de Superación Académica (DSA).</li>
              <li>Contar con la Apertura de una Cuenta Especifica a nombre del Programa y esta deberá de ser por Instituto Tecnológico de adscripción laboral.</li>
            </ul>
          </p>
        </div>

        <div className="div-pregunta">
          <h1>Derechos y obligaciones de los beneficiarios</h1>
          <p>
            <ul style={{listStyle:"decimal"}}>
              <li>Recibir un trato atento, digno y respetuoso, sin discriminación alguna;</li>
              <li>Recibir asesoría y apoyo sobre la operación del mismo de manera gratuita;</li>
              <li>Tener acceso a la información necesaria, de manera clara y oportuna, para resolver sus dudas respecto de las acciones del Programa;</li>
              <li>Recibir el comunicado por parte de las instancias ejecutoras sobre la asignación del recurso, y;</li>
              <li>Tener la reserva y privacidad de sus datos personales en los términos de lo establecido en la Ley General de Transparencia y Acceso a la Información Pública, la Ley Federal de Transparencia y Acceso a la Información Pública, su Reglamento y demás normativa jurídica aplicable.</li>
            </ul>
          </p>

          <p>Población objetivo: El Programa está dirigido a Profesores de Tiempo Completo (PTC) que pertenecen a los Institutos Tecnológicos del TecNM (Federales y Descentralizados).</p>

          <p>Establecer quién es la Instancia Normativa, las Instancias Ejecutoras y Órganos de Control participantes en el programa, así como sus datos de contacto.</p>

          <p>Medios institucionales para presentar quejas y denuncias <a href="http://www.google.com">Formato adjunto.</a></p>

          <p>Procedimientos para realizar las actividades de Contraloría Social, y</p>

          <p>Medidas para promover la equidad de género entre hombres y mujeres en la integración de los Comités.</p>
        </div>


        <div className="div-pregunta">
          <h1>Contraloria Social:</h1>
          <p>¿Qué es la Contraloría Social?
            <ul>
              <li>Constituye una práctica de transparencia, de rendición de cuentas y se convierte en un mecanismo de los beneficiarios para que, de manera organizada, verifiquen el cumplimiento de las metas y la correcta aplicación de los recursos públicos asignados al Programa para el Desarrollo Profesional Docente para el Tipo Superior, de la Convocatoria de Perfil Deseable Docente 2018, a cargo del Tecnológico Nacional de México.</li>
            </ul>
          </p>

          <p>¿Qué vigila la contraloría Social?
            <ul>
              <li>vigila el 100% de los recursos otorgados en el ejercicio fiscal que se vaya a realizar la Contraloría Social.</li>
            </ul>
          </p>

          <p>¿Qué es un Comité de Contraloría Social?
            <ul>
              <li>El Comité de Contraloría Social del PRODEP tienen la función de vigilar que se difunda información suficiente, veraz y oportuna sobre la operación del programa, registrar en los informes los resultados de las actividades realizadas, recibir quejas y/o inconformidades sobre la ejecución del programa, promoviendo la transparencia y rendición de cuentas en la aplicación de los recursos. <br/><br/>Este comité está integrado por profesores de tiempo completo que representan a los seis campus del TecMM.</li>
            </ul>
          </p>

          <p>Objetivo de los Comités de Contraloría Social.
            <ul>
              <li>El cumplimiento de las metas y la correcta aplicación de los recursos públicos asignados al Programa para el Desarrollo Profesional Docente para el Tipo Superior, de la Convocatoria de Perfil Deseable Docente, a cargo del Tecnológico Nacional de México.</li>
            </ul>
          </p>

          <p>¿Cuáles son las funciones que deberán de cumplir los Comités de Contraloría Social?
            <ul style={{listStyle:"lower-latin"}}>
              <li>Solicitar a la Instancia Ejecutora la información pública relacionada con la operación del Programa PRODEP.</li>
              <li>Vigilar que se difunda información suficiente, veraz y oportuna sobre la operación del Programa PRODEP</li>
              <li>Vigilar que el ejercicio de los recursos públicos para los apoyos que proporciona el PRODEP sea oportuno transparente y con apego a lo establecido en las reglas de operación.</li>
              <li>Vigilar que se difunda el padrón de beneficiarios.</li>
              <li>Vigilar que los beneficiarios del programa federal cumplan con los requisitos para tener ese carácter.</li>
              <li>Vigilar que se cumpla con los periodos de ejecución de la entrega de los apoyos.</li>
              <li>Vigilar que exista documentación comprobatoria del ejercicio de los recursos públicos y de la entrega de los apoyos.</li>
              <li>Vigilar que el programa federal no se utilice con fines políticos, electorales, de lucro u otros distintos al objeto del programa federal.</li>
              <li>Vigilar que el programa se ejecute en un marco de igualdad entre mujeres y hombres.</li>
              <li>Vigilar que las autoridades competentes proporcionen atención a las quejas y denuncias relacionadas con el programa.</li>
              <li>Registrar en los informes los resultados de las actividades de contraloría social realizadas, así como dar seguimiento, en su caso, a los mismos.</li>
              <li>Recibir las quejas y denuncias sobre la aplicación y ejecución de los programas federales, recabar la información de las mismas y en su caso, presentar con la información recopilada a la Instancia Ejecutora del programa a efecto de que se tomen las medidas a que haya lugar,</li>
              <li>Recibir las quejas y denuncias que puedan dar lugar al fincamiento de responsabilidades administrativas, civiles o penales relacionadas con los programas federales, así como turnarlas a las autoridades competentes para su atención.</li>
            </ul>
          </p>

          <p>Documentación relacionada con la C.S., deberá de presentar un orden de acuerdo a lo siguiente:
            <ul style={{listStyle:"decimal"}}>
              <li>Esquema de Contraloría Social.</li>
              <li>Programa Anual de Trabajo de Contraloría Social (PATCS), para la Instancia Normativa y para la Instancia Ejecutora el Programa Institucional (PITCS).</li>
              <li>Guía Operativa de Contraloría Social.</li>
              <li>Formatos.</li>
            </ul>
          </p>
        </div>




      </div>


      <LinksFooter/>

      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(ContraloriaSocial, options);
