
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './styles/building.css';
import bannerTNM from '../header-nuevo2.jpg'
import LinksFooter from './linksFooter.js'
import buildingImg from './images/building.png'


class Building extends Component {


  render() {

    return (

      <div className="div-principal-building">

        <img className="img-bannerTNM" src={bannerTNM}/>

        <div className="div-buildingImagen">
          <img src={buildingImg}/>
        </div>

        <LinksFooter/>
      </div>
    );
  }
}

export default Building;
