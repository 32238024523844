import React, { Component } from 'react';
import {withGetScreen} from 'react-getscreen'
import {Carousel} from 'react-responsive-carousel';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';

import Noticias from './noticieroData.js'
import './styles/noticiero.css';


class CarouselItemNoticiero extends Component{

  constructor(props){
    super();
    this.state={

    }
    this.clickImageNoticia = this.clickImageNoticia.bind(this);
  }

  clickImageNoticia(){

    var parrafosNoticiaLocal = this.props.parrafosNoticia

    var parrafosNoticiaLocalString = JSON.stringify(parrafosNoticiaLocal)

    var cacheDataNoticia = {titulo:this.props.titulo,
                            fechaLugar:this.props.fechaLugar,
                            imagenes:this.props.imagenes,
                            id:this.props.id,
                            parrafosNoticia:parrafosNoticiaLocalString,
                            }

    var cacheDataString = JSON.stringify(cacheDataNoticia);

    localStorage.setItem("noticia", cacheDataString);

  }


  render(){

    return(
      <Link to={{
        pathname:"/Noticia/"+this.props.pathTitulo,
        noticiaProps:{
          pathTitulo:this.props.pathTitulo
        }
      }}>
        <div  onClick={this.clickImageNoticia} className="div-CarouselItemNoticiero">
          <h2>{this.props.titulo}</h2>
          <img src={this.props.imagen} style={{maxWidth:"500px", maxHeight:"300px"}}/>
        </div>
      </Link>
    );
  }
};

class Noticiero extends Component {

  constructor(){
    super();
    this.state={
      selected : 0,
      noticiasArray : [],
      showProgress:"block"
    }
  }

  componentWillMount(){
    //localStorage.removeItem("noticia");
    //var noticiasArrayVar = Noticias();

    //localStorage.removeItem("carrera")

    var noticiasLocal
    var objConverted
    var noticiasFinal = []
    var counterSize

    const url = 'https://tecmm.edu.mx/dashboard_conexion_db.php'
    axios.get(url).then(response => response.data)
    .then((data) => {
      noticiasLocal=data
      counterSize=noticiasLocal.length

      for(var i=counterSize-1; i>=0;){

        objConverted={
          titulo:noticiasLocal[i].titulo,
          pathTitulo:noticiasLocal[i].pathTitulo,
          imagen:noticiasLocal[i].imagen,
          imagenesExtra:JSON.parse(noticiasLocal[i].imagenesExtra),
          contenido:JSON.parse(noticiasLocal[i].contenido)
        }

        //console.log("objeto convertido: ", objConverted)
        noticiasFinal.push(objConverted)

        this.setState({
        noticiasArray: noticiasFinal,
        showProgress:"none"
        })
        i--
      }

      console.log("arreglo final: ", this.state.noticiasArray)


     })
  }

  render() {

    const showProgress={
      display:this.state.showProgress,
      backgroundColor:"#6D356C"
    }

    if(this.props.isMobile()){
        return (

          <div className="div-principal-noticiero">

            <h1>NOTICIAS TECMM</h1>

            <div className="div-carousel-noticiero">
              <Carousel

                width="100%"
                centerMode
                centerSlidePercentage={/* cambiar este numero en base a la resolucion que se esta usando, 30 para escritorio y 50 para moviles */50}
                showIndicators={false}
                showStatus={false}
                showArrows={true}
                showThumbs={false}
                emulateTouch
                autoPlay
                >

                {this.state.noticiasArray.map((it)=>(
                  <CarouselItemNoticiero pathTitulo={it.pathTitulo} titulo={it.titulo} imagen={it.imagen}/>
                ))}

              </Carousel>
            </div>
          </div>
      );
    }

    return(
      <div className="div-principal-noticiero">

        <h1>NOTICIAS TECMM</h1>

        <LinearProgress style={showProgress} />

        <div className="div-carousel-noticiero">
          <Carousel
            style={{backgroundColor:"red"}}
            width="100%"
            centerMode
            centerSlidePercentage={/* cambiar este numero en base a la resolucion que se esta usando, 30 para escritorio y 50 para moviles */30}
            showIndicators={false}
            showStatus={false}
            showArrows={true}
            showThumbs={false}


            >

            {this.state.noticiasArray.map((it)=>(

              <CarouselItemNoticiero pathTitulo={it.pathTitulo} titulo={it.titulo} imagen={it.imagen}/>

            ))}

          </Carousel>
        </div>
      </div>
    );

  }
}

const options = {mobileLimit: 420, tabletLimit: 800}
export default withGetScreen (Noticiero, options);
