
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen'
import Typist from 'react-typist';

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'


import './styles/contacto.css'
import bannerTNM from '../header-nuevo2.jpg'
class DirectorioItem extends Component{
  constructor(props){
    super(props);
    this.state={

    }
  }

  render(){
    return(
      <div class="card">
        <div class="content">
          <div class="header">
            {this.props.nombre}
          </div>
          <div class="meta">
            {this.props.telefono}
          </div>
          <div class="description">
            {this.props.direccion}
          </div>
        </div>
        <div class="extra content">
          <div class="ui two buttons">
          <button class="ui labeled icon button">
            <i class="phone icon"></i>
            <a className="prueba" target="_blank" href={this.props.telefonoPrincipal}>llamar</a>
          </button>

          </div>
        </div>
      </div>

    );
  }




}


class Contacto extends Component{

  constructor(props){
    super(props);
    this.state={
      directorio:[
        {nombre:"CAMPUS ARANDAS", direccion:"Av. José Guadalupe Tejeda 557 Arandas, Jalisco.", telefonoPrincipal:"tel:348-78-32020", telefono:"348-78-32020, 01-348-78-32010"},
        {nombre:"CAMPUS CHAPALA", direccion:"Libramiento Chapala - Ajijic 200 Chapala, Jalisco.", telefonoPrincipal:"tel:01-376-76-5-80-30", telefono:"01-376-76-5-80-30"},
        {nombre:"CAMPUS COCULA", direccion:"Calle Tecnológico 1000, Lomas de Cocula, Cocula, Jalisco.", telefonoPrincipal:"tel:377-773-0030", telefono:"377-773-0030"},
        {nombre:"CAMPUS EL GRULLO", direccion:"CAMPUS EL GRULLO Km. 5 carretera El Grullo - Ejutla s/n, Puerta de Barro, El Grullo, Jalisco.", telefonoPrincipal:"tel:321-38-73435", telefono:"321-38-73435"},
        {nombre:"CAMPUS LA HUERTA", direccion:"Rafael Palomera No. 161, Col. El Maguey, La Huerta, Jalisco.", telefonoPrincipal:"tel:357-38-41884", telefono:"357-38-41884"},
        {nombre:"CAMPUS LAGOS DE MORENO", direccion:"Libramiento Tecnológico 5000, Col. Portugalejo de los Romanes, Lagos de Moreno, Jalisco.", telefonoPrincipal:"tel:01-(474)-403-39-90", telefono:"01-(474)-403-39-90"},
        {nombre:"CAMPUS MASCOTA", direccion:"Km. 100 Carretera Ameca-Mascota, Chan Rey, Mascota, Jalisco.", telefonoPrincipal:"388-38-52010", telefono:"tel:388-38-52010 y 01-388-38-60518"},
        {nombre:"CAMPUS PUERTO VALLARTA", direccion:"Corea del Sur no. 600 Col. El Mangal, Coapinole, Puerto Vallarta, Jalisco.", telefonoPrincipal:"tel:322-226-56 00", telefono:"322-226-56 00"},
        {nombre:"CAMPUS TALA", direccion:"Avenida Tecnológico S/N Tala, Jalisco.", telefonoPrincipal:"tel:384-73-33000", telefono:"384-73-33000"},
        {nombre:"CAMPUS TAMAZULA", direccion:"Carretera Tamazula Santa Rosa 329 Tamazula de Gordiano, Jalisco.", telefonoPrincipal:"tel:358-10-30060", telefono:"358-10-30060 Ext. 1015"},
        {nombre:"CAMPUS TEQUILA", direccion:"Dr. Joel Magallanes Rubio 501 Tequila, Jalisco.", telefonoPrincipal:"tel:37-47-42-72-88", telefono:"37-47-42-72-88"},
        {nombre:"CAMPUS ZAPOTLANEJO", direccion:"Carretera Libre Zapotlanejo-Tepatitlán Km 4.5 Av. Tecnológica No.300 Predio Huejotitán, Zapotlanejo, Jalisco.", telefonoPrincipal:"tel:373-73-56060", telefono:"373-73-56060"},
        {nombre:"CAMPUS ZAPOPAN", direccion:"Camino Arenero 1101 Zapopan, Jalisco.", telefonoPrincipal:"tel:36-82-11-80", telefono:"36-82-11-80"},
        {nombre:"DIRECCIÓN GENERAL", direccion:"Camino Arenero 1101, Edificio de Dirección General, Zapopan, Jalisco.", telefonoPrincipal:"tel:38-84-94-70", telefono:"38-84-94-70"},
      ]
    }
  }

  componentWillMount(){

  }

  render(){


    return(
      <div className="div-principal-contacto">
        {this.props.isMobile() || this.props.isTablet()?(
          <div>
            <MenuCentralMovil/>
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
          </div>

          ) : (
          <div>
            <a href="/">
              <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            </a>
            <MenuCentral/>
          </div>
        )}

        <div className="div-portadaContacto">
          <h1 className="h1-portadaContacto">
            CONTACTO
          </h1>
        </div>

        <div className="div-directorio">
          <div class="ui cards">

            {this.state.directorio.map((it)=>(

              <DirectorioItem nombre={it.nombre} direccion={it.direccion} telefono={it.telefono} telefonoPrincipal={it.telefonoPrincipal}/>

            ))}

            </div>



        </div>

        <LinksFooter/>

      </div>
    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Contacto, options);
