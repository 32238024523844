import React, { Component } from 'react';
import Parallax from 'react-springy-parallax'

import './styles/festival.css'

class Festival extends Component {

  constructor(props) {
     super(props);
     this.state = {

     }
   }

  render() {

    const styles = {
      fontFamily: 'Menlo-Regular, Menlo, monospace',
      fontSize: 14,
      lineHeight: '10px',
      color: 'white',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundImage:"url('./images/deploy.svg')"
    }

    return(
      <Parallax ref="parallax" pages={3}>

          <Parallax.Layer offset={0} speed={1} style={{ backgroundColor: 'white' }} />
          <Parallax.Layer offset={1} speed={1} style={{ backgroundColor: 'white' }} />
          <Parallax.Layer offset={2} speed={1} style={{ backgroundColor: 'white' }} />

          <Parallax.Layer offset={0} speed={0.5} style={styles} >
            <img className="img-bannerFestival" src="http://tecmm.edu.mx/imagesReact/images/festival/banner-festival.png"/>
            <ul className="ul-menu-festival">

              <li onClick={() => this.refs.parallax.scrollTo(1)} className="">
                Antecedentes
              </li>

              <li onClick={() => this.refs.parallax.scrollTo(2)}  className="">
               Diciplinas
              </li>

              <li className="">
                <a target="_blank" href="http://tecmm.edu.mx/documentos/festival-arte-y-cultura/convocatoria-festival.pdf">Convocatoria</a>
              </li>

              <li className="">
                Homenaje
              </li>

              <li className="">
                Sedes
              </li>

              <li className="">
                Organigrama
              </li>

              <li className="">
                Galeria
              </li>

              <li className="">
                <a target="_blank" href="http://tecmm.edu.mx/documentos/festival-arte-y-cultura/aportaciones-mario-molina.pdf">Aportaciones</a>
              </li>

            </ul>

            <img  src="http://tecmm.edu.mx/imagesReact/images/festival/actor-festival.jpg"/>
          </Parallax.Layer>

          <Parallax.Layer offset={1} speed={-0.1} style={styles}>
              pagina 1
          </Parallax.Layer>

          <Parallax.Layer offset={2} speed={0.5} style={styles}>
              pagina 2
          </Parallax.Layer>

      </Parallax>
    );
  }
}

export default Festival;
