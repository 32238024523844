import React, { Component } from 'react';
import axios from 'axios';
import {withGetScreen} from 'react-getscreen'
import LinearProgress from '@material-ui/core/LinearProgress';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'

import './styles/noticia.css';

//sacar el id del path : {this.props.match.params.noticiaNombre}



class Noticia extends Component {
  constructor(){
    super();
    this.state={
      noticias:[],
      showInfo: "block"
    }

    this.buttonMostrarInfo = this.buttonMostrarInfo.bind(this);
  }

  componentWillMount(){
    var noticiasLocal
    var objConverted
    var noticiasFinal = []

    const url = 'http://tecmm.edu.mx/dashboard_conexion_db.php'
    axios.get(url).then(response => response.data)
    .then((data) => {
      noticiasLocal=data
      console.log("objeto recibido: ", noticiasLocal)
      for(var i=0; i<noticiasLocal.length; i++){

        objConverted={
          titulo:noticiasLocal[i].titulo,
          pathTitulo:noticiasLocal[i].pathTitulo,
          imagen:noticiasLocal[i].imagen,
          imagenesExtra:JSON.parse(noticiasLocal[i].imagenesExtra),
          contenido:JSON.parse(noticiasLocal[i].contenido)
        }


        console.log("objeto convertido: ", objConverted)
        noticiasFinal.push(objConverted)

        this.setState({
        noticias: noticiasFinal,
        showInfo:"none"
        })

      }


     })
  }

  buttonMostrarInfo(){
    console.log("state noticias: ", this.state.noticias)

  }

  render() {

    const divInfo={
      display:this.state.showInfo,
      backgroundColor:"#6D356C",
    }

    return (
      <div className="div-principalNoticias">
      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-bannerTNM" src={"http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"}/>
          <img className="img-bannerTNM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

        ) : (
        <div>
          <a href="/">
            <img className="img-contraloriaSocial-bannerTNM" src={"http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"}/>
          </a>
          <MenuCentral/>
        </div>
        )}

        <LinearProgress style={divInfo} />

        <div className="div-parent">
          <div className="div-noticia">
            {this.state.noticias.map((it, key)=>(

              it.pathTitulo == this.props.match.params.noticiaNombre?(
                <div>

                  <h1 className="h1-tituloNoticia">{it.titulo}</h1>

                  <img className="img-imagenPrincipal" src={it.imagen}/>

                  <div className="div-imagenesExtra">
                    {it.imagenesExtra.map((ie)=>(
                      <img style={{margin:"1%", maxWidth:"150px", maxHeight:"150px"}} src={ie.imagen}></img>
                    ))}
                  </div>

                  <div className="div-contenidoNoticia">
                    {it.contenido.map((ci)=>(
                      ci.tipo == "parrafo"?(

                        <p className="p-subtituloNoticia">{ReactHtmlParser(ci.texto)}</p>

                      ):(

                        <h2 className="h2-subtituloNoticia">{ci.texto}</h2>

                      )
                    ))}
                  </div>

                </div>

              ):(
                <div> </div>
              )

            ))}
          </div>

          <div className="div-timeLineNoticia">
            <h1 className="h1-timeLine"> Noticias Relacionadas: </h1>
            <div className="div-timeLineContainer">
              {this.state.noticias.map((it, key)=>(

                <div className="div-timeLineItem">

                  <Link to={{ pathname:"/Noticia/"+it.pathTitulo }}>
                    <h1 className="h1-timeLineItem">{it.titulo}</h1>
                    <img className="img-timeLineItem" src={it.imagen}/>
                  </Link>

                </div>

              ))}
            </div>

          </div>

        </div>

        <LinksFooter/>
      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Noticia, options);
