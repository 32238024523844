import React, {Component} from 'react'
import {withGetScreen} from 'react-getscreen'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'

import './styles/movilidadInternacional.css'

class MovilidadInternacional extends Component{

  constructor(props){
    super(props);
    this.state={

    }
  }

  render(){
    return(
      <div className="div-principal-movilidadInternacional">
        {this.props.isMobile() || this.props.isTablet()?(
          <div>
            <MenuCentralMovil/>
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
          </div>

          ) : (
          <div>
            <a href="/">
              <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            </a>
            <MenuCentral/>
          </div>
        )}

        <div className="div-informacion-movilidadInternacional">
          <h1>Movilidad internacional</h1>

        </div>

        <LinksFooter/>
      </div>

    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(MovilidadInternacional, options);
