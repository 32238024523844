
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './styles/descubreTECMM.css';



const image05 = 'http://tecmm.edu.mx/imagesReact/images/descubreTECMM/Formacion-Distancia.jpg'
const image08 = 'http://tecmm.edu.mx/imagesReact/images/descubreTECMM/Posgrados.jpg'
const image12 = 'http://tecmm.edu.mx/imagesReact/images/descubreTECMM/Gaceta-TECMM.jpg'
const image13 = 'http://tecmm.edu.mx/imagesReact/images/descubreTECMM/TECMM-Internacionalizate.jpg'
const CuadroRojo = 'http://tecmm.edu.mx/imagesReact/images/descubreTECMM/convocatorias.jpg'

class DescubreTECMM extends Component{

  constructor(props){
    super();
  }

  render(){
    return(


      <div className="div-principal-cardsInteres">
        <h1> DESCUBRE EL TECMM </h1>
        <div className="div-imagen-cards">


            <Link to="/Convocatorias">

              <img src={CuadroRojo} style={{marginRight:"2px"}} className="img-card"/>

            </Link>

            <a href="https://drive.google.com/file/d/1YqizLTT8YO-ToPB5oNkx6crYJiLeySun/view?fbclid=IwAR0DYTQg_6zbqCm4QhL_-JMVLI4Q-hWdV0JAewXcqyfm7WsT6Ev_KzeK2WU">
              <img src={image12} style={{marginRight:"2px"}} className="img-card"/>
            </a>



            <a href="">
              <img src={image05} style={{marginRight:"2px"}} className="img-card"/>
            </a>

            <a href="http://www.posgrado.tecmm.edu.mx/">
              <img src={image08} className="img-card"/>
            </a>

        </div>
      </div>

    );
  }


}

export default DescubreTECMM;
