
import React, { Component } from 'react';
import { Form, Input, TextArea, Button, Select } from 'semantic-ui-react'
import axios from 'axios';
import {withGetScreen} from 'react-getscreen'


import bannerTNMDenunciaContraloria from '../header-nuevo2.jpg'
import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'
import './styles/denunciaContraloria.css';

class DenunciaContraloria extends Component {

    constructor(props){
      super(props);
      this.state = {
        name: 'default',
        address:'default',
        zipCode:'default',
        phone:'default',
        eMail:'',
        place:'default',
        date:'default',
        message:'default',
        files:'default',
        submittedNombre: '',
        submittedDomcilio: '',
        submittedCodigoPostal: '',
        submittedTelefono: '',
        submittedEMail: '',
        submittedLugar: '',
        submittedCuando: '',
        submittedHechos: '',
      }
    }

    //handleChange = (e, { name, value }) => this.setState({ [name]: e.target.value })

    handleChange = (e) => {

      var campo=e.target.name

      this.setState({
        [campo]: e.target.value
        });
    }

    handleSubmit = () => {

      var nameSubmit=this.state.name
      var addressSubmit=this.state.address

      const myObj={
        tipoDenuncia:"denunciaContraloria",
        nombre:this.state.nombre,
        domicilio:this.state.domicilio,
        codigoPostal:this.state.codigoPostal,
        telefono:this.state.telefono,
        eMail:this.state.eMail,
        lugar:this.state.lugar,
        cuando:this.state.cuando,
        hechos:this.state.hechos
      }


      alert("nombre: "+this.state.nombre+"\n"+"domicilio: "+this.state.domicilio+"\n"+"c.p: "+this.state.codigoPostal+"\n"+"telefono: "+this.state.telefono+"\n"+"eMail: "+this.state.eMail+"\n"+"lugar: "+this.state.lugar+"\n"+"cuando: "+this.state.cuando+"\n"+"hechos: "+this.state.hechos)

      var datosDenuncia = JSON.stringify(myObj);

      console.log(datosDenuncia);

      axios.post('http://tecmm.edu.mx/mail.php', datosDenuncia)
      .then(function (response) {
        alert(response);
      })
      .catch(function (error) {
        alert(error);
      });




    }

    render() {

      return (
        <div className="div-principalFormulario">

        {this.props.isMobile() || this.props.isTablet()?(
          <div>
            <MenuCentralMovil/>
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
          </div>

          ) : (
          <div>
            <a href="/">
              <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            </a>
            <MenuCentral/>
          </div>
        )}

          <p className="p-instruccionesFormulario"><p>INSTRUCCIONES:</p>
             Ingrese los datos solicitados siendo lo mas especifico posible, el Órgano de Control del Instituto
             Tecnológico José Mario Molina Pasquel y Henríquez garantiza la absoluta confidencialidad de los datos que se presentan
             en este sitio, (VER AVISO DE PRIVACIDAD) sin embargo, en caso de querer hacer denuncias anónimas, deberá informar
             con veracidad, la forma de contactarlo</p>

              <Form className="form-denunciaContraloria" onSubmit={this.handleSubmit}>

                <div className="div-datosFormulario">

                  <h3>Ingrese Los Siguientes Datos</h3>

                  Nombre Completo:<br/>
                  <input type="text" name="nombre" value={this.state.nombre} onChange={this.handleChange}/>
                  <br/><br/>

                  Domicilio:<br/>
                  <input type="text" name="domicilio" value={this.state.domicilio} onChange={this.handleChange}/>
                  <br/><br/>

                  C.P:<br/>
                  <input type="text" name="codigoPostal" value={this.state.codigoPostal} onChange={this.handleChange}/>
                  <br/><br/>

                  Telefono:<br/>
                  <input type="text" name="telefono" value={this.state.telefono} onChange={this.handleChange}/>
                  <br/><br/>

                  Correo Electronico:<br/>
                  <input type="text" name="eMail" value={this.state.eMail} onChange={this.handleChange}/>
                  <br/><br/>

                </div>

                <div className="div-descripcionFormulario">

                  <h3> Describa de manera detallada los hechos</h3>


                  Lugar:<br/>
                  <input type="text" name="lugar" value={this.state.lugar} onChange={this.handleChange}/>
                  <br/><br/>

                  Cuando:<br/>
                  <input type="text" name="cuando" value={this.state.cuando} onChange={this.handleChange}/>
                  <br/><br/>

                  Hechos:<br/>
                  <textarea  className="textarea-hechosFormulario" name="hechos" value={this.state.hechos} onChange={this.handleChange}></textarea>
                  <br/><br/> <br/>


                      <input style={{display:"none"}} type="file" name="file-1[]" id="file-1" class="inputfile inputfile-1" data-multiple-caption="{count} files selected" multiple />
                      <label for="file-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                      </svg> <span>Elige un Archivo&hellip;</span></label>

                </div>

              </Form>




          <div className="div-botonesFormulario">
            <button className="botonCancelar">Cancelar </button>
            <button className="botonEnviar" onClick={this.handleSubmit}>Enviar </button>
          </div>

          <LinksFooter/>
        </div>
      )
    }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(DenunciaContraloria, options);
