import React, { Component } from 'react';
import {withGetScreen} from 'react-getscreen'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'

import './styles/juntasDeGobierno.css'



class JuntasDeGobierno extends Component{

    constructor(props){
      super(props);
      this.state={
        carrerasArray:null,
      }

    }


  render(){
    return(
      <div className="div-principal-juntasDeGobierno">

      {this.props.isMobile() || this.props.isTablet()?(
      <div>
        <MenuCentralMovil/>
        <img className="img-juntasDeGobierno-bannerTNM" src={"http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"}/>
        <img className="img-nombreTECMM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
      </div>

      ) : (
      <div>
        <a href="/">
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
        </a>
        <MenuCentral/>
      </div>
      )}

      <div className="div-oficios-juntasDeGobierno">
        <h1>JUNTAS DE GOBIERNO</h1>

        <div className="div-lista-juntasDeGobierno">
          <ul>
            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2019/Junta_de_Gobierno_28_Febrero_2019.pdf">2da. Sesión Extraordinaria 28/Febrero/2019</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2019/Junta_de_Gobierno_17_Enero_2019.pdf">1ra. Sesión Extraordinaria 17/Enero/2019</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_28_Noviembre_2018.pdf">3ra. Sesión Ordinaria 28/Noviembre/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_13_Noviembre_2018.pdf">4ta. Sesión Extraordinaria 13/Noviembre/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_18_Octubre_2018.pdf">3ra. Sesión Extraordinaria 18/Octubre/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gonierno_18_Julio_2018.pdf">2da. Sesión Ordinaria 18/Julio/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_06_Junio_2018.pdf">2da. Sesión Extraordinaria 06/Junio/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_23_Marzo_2018.pdf">1ra. Sesión Ordinaria 23/Marzo/2018</a>
            </li>

            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2018/Junta_de_Gobierno_31_Enero_2018.pdf">1ra. Sesión Extraordinaria 31/Enero/2018</a>
            </li>


            <li>
              <a href="http://tecmm.edu.mx/juntasDeGobierno(oficios)/2017/Junta_de_Gobierno_05_Diciembre_2017.pdf">3ra. Sesión Ordinaria 05/Diciembre/2017</a>
            </li>

          </ul>
        </div>
      </div>


      <LinksFooter/>

      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(JuntasDeGobierno, options);
