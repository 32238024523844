import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen'
import { Grid, Image } from 'semantic-ui-react'


import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'
import CardsComponent from './cardsComponent.js'

import './styles/ofertaAcademicaCampus.css'
import bannerTNM from '../header-nuevo2.jpg'

import Carreras from './carrerasData.js'



class CarreraItem extends Component{

  constructor(props){
    super(props);
    this.state={

    }

    this.clickImage = this.clickImage.bind(this);
  }

  clickImage(){

    var perfilEgresoLocal = this.props.perfil
    var perfilEgresoLocalString = JSON.stringify(perfilEgresoLocal)


    var cacheData = {portada:this.props.portada, youtube:this.props.youtube, campus:this.props.campus, objetivo:this.props.objetivo,
                     perfilEgreso:perfilEgresoLocalString, reticula:this.props.reticula, folleto:this.props.folleto}

    var cacheDataString = JSON.stringify(cacheData);

    localStorage.setItem("carrera", cacheDataString);

  }


  render(){

    const newTo = {
      pathname: "/Carrera/"+this.props.nombre,
    };


    return(

        <Link to={newTo}>
          <img onClick={this.clickImage} className="img-ItemCarrera" src={this.props.card}/>
        </Link>

    );
  }
}




class OfertaAcademicaCampus extends Component{

    constructor(props){
      super(props);
      this.state={
        carrerasArray:null,
      }



    }

    componentWillMount(){

      var carrerasArrayVar = Carreras();

      localStorage.removeItem("carrera")

      this.setState({
         carrerasArray:carrerasArrayVar,
      })
    }




    render(){




    return(
      <div className="div-principal-ofertaAcademica">

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-ofertaAcademica-bannerTNM" src={bannerTNM}/>

          <img className="img-nombreTECMM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

      ) : (
        <div>
          <a href="/">
            <img className="img-ofertaAcademica-bannerTNM" src={bannerTNM}/>
          </a>
          <MenuCentral/>
        </div>
      )}





        <img className="img-ofertaAcademica-portada" src={'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/portadaOfertaAcademica.jpg'}/>

        <div className="div-carreras">
          <Grid.Row centered columns={4}>
              {this.state.carrerasArray.map((it, key)=>(
                <CarreraItem keyIdenty={key} nombre={it.name} card={it.card} portada={it.portada} youtube={it.youtube} objetivo={it.objetivo} perfil={it.perfilEgreso} reticula={it.reticula} folleto={it.folleto} campus={it.campus}/>
              ))}
          </Grid.Row>
        </div>



        <LinksFooter/>

      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(OfertaAcademicaCampus, options);
