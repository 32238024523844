import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen'

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import YouTube from 'react-youtube';


import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'
import CardsComponent from './cardsComponent.js'

import './styles/carrera.css'
import bannerTNM from '../header-nuevo2.jpg'

import Carreras from './carrerasData.js'





class Carrera extends Component{

  constructor(props){
    super(props);
    this.state={
      visible: false,
      portada:null,
      youtube:null,
      campus:null,
      objetivo:null,
      perfilEgresoArray:null,
      reticula:null,
      folleto:null,
      planEstudios:null,
    }
  }

  componentWillMount(){

    /*var carreraLocalString = localStorage.getItem("carrera")
    var carreraLocalParse = JSON.parse(carreraLocalString)

    var portadaLocal = carreraLocalParse.portada
    var youtubeLocal = carreraLocalParse.youtube
    var campusLocal = carreraLocalParse.campus
    var objetivoLocal = carreraLocalParse.objetivo
    var perfilEgresoLocal = JSON.parse(carreraLocalParse.perfilEgreso)
    var reticulaLocal = carreraLocalParse.reticula
    var folletoLocal = carreraLocalParse.folleto

    console.log("aqui se muestra lo recibido: "+carreraLocalParse.perfilEgreso)

    this.setState({
      portada:portadaLocal,
      youtube:youtubeLocal,
      campus:campusLocal,
      objetivo:objetivoLocal,
      perfilEgresoArray:perfilEgresoLocal,
      reticula:reticulaLocal,
      folleto:folletoLocal,
    })*/
    var carrerasArrayVar = Carreras();
    var carreraPath = this.props.match.params.carreraId
    var self=this;

    carrerasArrayVar.forEach(function(it){
      console.log(it);

      if(it.name == carreraPath){
        self.setState({
          portada:it.portada,
          youtube:it.youtube,
          campus:it.campus,
          objetivo:it.objetivo,
          perfilEgresoArray:it.perfilEgreso,
          reticula:it.reticula,
          folleto:it.folleto,
          planEstudios:it.planEstudios,
        })
      }else{

      }
    });


  }

  show() {
    this.setState({ visible: true });
  }

  hide() {
      this.setState({ visible: false });
  }

  _onReady(event) {
   // access to player in all event handlers via event.target
   event.target.pauseVideo();
 }


  render(){

    const opts = {
      height: '500',
      width: '750',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };


    return(
      <div className="div-principal-carrera">

      <Rodal width="800" height="550" visible={this.state.visible} onClose={this.hide.bind(this)}>
          <div>
            <YouTube
              videoId={this.state.youtube}
              opts={opts}
              onReady={this._onReady}
            />
          </div>
      </Rodal>

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-carrera-bannerTNM" src={bannerTNM}/>

          <img className="img-nombreTECMM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

      ) : (
        <div>
          <a href="/">
            <img className="img-carrera-bannerTNM" src={bannerTNM}/>
          </a>
          <MenuCentral/>
        </div>
      )}


        <div>

          <img className="img-portadaCarrera" src={this.state.portada}/>

          <div className="div-Carrera">

            <div className="div-infoCarrera">

              <h2 className="h2-objetivo">OBJETIVO GENERAL</h2>
              <p className="p-objetivo">
                {this.state.objetivo}
              </p>

              <h2 className="h2-objetivo">PERFIL DE EGRESO</h2>
              <ul>

              {this.state.perfilEgresoArray.map((it)=>(
                <li>
                  <p>{it.perfil}</p>
                </li>

              ))}

              </ul>

              <h2 className="h2-objetivo"> OFERTADA EN LOS CAMPUS </h2>
              <p className="p-campus">
                {this.state.campus}
              </p>

            </div>

            <div className="div-botonesCarrera">

              <a href="http://controlescolar.tecmm.edu.mx/PDF'S/inscripcion/Formato-Control-Escolar-INSCRIPCION-FEBERO-JULIO-2019-1.pdf">
                <button> INSCRÍBETE</button>
              </a>


              <button onClick={this.show.bind(this)}>VIDEO</button>


              <a href={this.state.reticula} download>
                <button>RETICULA</button>
              </a>

              <a href={this.state.planEstudios} download>
                <button>PLAN DE ESTUDIOS</button>
              </a>

              <Link to={'/Contacto'}>
                <button>CONTACTANOS</button>
              </Link>

              <a href={this.state.folleto} download>
                <button>FOLLETO</button>
              </a>

            </div>

          </div>

        </div>

        <LinksFooter/>

      </div>
    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Carrera, options);
