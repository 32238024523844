
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ModeloAcademicoAnimacion from './modeloAcademicoAnimacion.js'
import LinksFooter from './linksFooter.js'
import MenuCentral from './menuCentral.js'

import './styles/modeloAcademico.css';
import bannerTNM from '../header-nuevo2.jpg'





class ModeloAcademico extends Component {

  constructor(){
    super();
    this.state={

    }
  }


  render() {

    return (



      <div className="div-principal-modeloAcademico">

        <a href="/">
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
        </a>

        <MenuCentral/>

        <div className="div-ModeloAcademicoAnimacion">
          <ModeloAcademicoAnimacion/>
        </div>


        <LinksFooter/>

      </div>
    );
  }
}

export default ModeloAcademico;
