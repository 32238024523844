import React, { Component } from 'react';
import axios from 'axios';
import {withGetScreen} from 'react-getscreen'


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Dropdown } from 'semantic-ui-react'



import 'rodal/lib/rodal.css';

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'
import bannerTNM from '../header-nuevo2.jpg'


import './styles/denunciaAcoso.css'


class DenunciaAcoso extends Component {

  constructor(){
    super();
    this.state={

      trabajaAdministracionPublica:true,
      tipoDenuncia:"",
      nombreDenunciante:"",
      puestoDenunciante:"",
      telefonoDenunciante:"",
      eMailDenunciante:"",
      domicilioDenunciante:"",
      nombreDenunciado:"",
      puestoDenunciado:"",
      dependenciaDenunciado:"",
      fechaHechos:"",
      horaHechos:"",
      lugarHechos:"",
      frecuenciaHechos:"",
      descripcionHechos:"",
      nombreTestigo:"",
      domicilioTestigo:"",
      telefonoTestigo:"",
      eMailTestigo:"",
      dependenciaTestigo:"",
    }
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(){
    this.setState({ trabajaAdministracionPublica: !this.state.trabajaAdministracionPublica });
    alert(this.state.nombreDenunciante+"\n"+this.state.puestoDenunciante+"\n"+this.state.telefonoDenunciante
          +"\n"+this.state.eMailDenunciante+"\n"+this.state.domicilioDenunciante)
  };

  handleChangeDatos = (e) => {

    var campo=e.target.name

    this.setState({
      [campo]: e.target.value
      });
  }

  handleSubmit = () => {

    const datosDenunciaAcoso={
      tipoDenuncia:"denunciaAcoso",

      nombreDenunciante:this.state.nombreDenunciante,
      puestoDenunciante:this.state.puestoDenunciante,
      telefonoDenunciante:this.state.telefonoDenunciante,
      eMailDenunciante:this.state.eMailDenunciante,
      domicilioDenunciante:this.state.domicilioDenunciante,

      nombreDenunciado:this.state.nombreDenunciado,
      puestoDenunciado:this.state.puestoDenunciado,
      dependenciaDenunciado:this.state.dependenciaDenunciado,

      fechaHechos:this.state.fechaHechos,
      horaHechos:this.state.horaHechos,
      lugarHechos:this.state.lugarHechos,
      frecuenciaHechos:this.state.frecuenciaHechos,
      descripcionHechos:this.state.descripcionHechos,

      nombreTestigo:this.state.nombreTestigo,
      domicilioTestigo:this.state.domicilioTestigo,
      telefonoTestigo:this.state.telefonoTestigo,
      eMailTestigo:this.state.eMailTestigo,
      dependenciaTestigo:this.state.dependenciaTestigo
    }

    alert("nombre: "+this.state.nombre+"\n"+"domicilio: "+this.state.domicilio+"\n"+"c.p: "+this.state.codigoPostal+"\n"+"telefono: "+this.state.telefono+"\n"+"eMail: "+this.state.eMail+"\n"+"lugar: "+this.state.lugar+"\n"+"cuando: "+this.state.cuando+"\n"+"hechos: "+this.state.hechos)

    var datosDenuncia = JSON.stringify(datosDenunciaAcoso);


    console.log(datosDenuncia);

    axios.post('http://tecmm.edu.mx/mail.php', datosDenuncia)
    .then(function (response) {
      alert(response);
    })
    .catch(function (error) {
      alert(error);
    });

  }

  render() {

    const tiposDenuncias = [
                            {
                              key: 'acosoSexual',
                              text: 'Acoso Sexual',
                              name: 'acosoSexual',
                            },
                            {
                              key: 'hostigamiento',
                              text: 'Hostigamiento',
                              name: 'hostigamiento',
                            },
                            {
                              key: 'discriminacion',
                              text: 'Discriminacion',
                              name: 'discriminacion',
                            },
                            {
                              key: 'laicidad',
                              text: 'Por Laicidad',
                              name: 'laicidad',
                            },
                            {
                              key: 'bullying',
                              text: 'Bullying',
                              name: 'bullying',
                            },
                            {
                              key: 'marginacion',
                              text: 'Marginacion',
                              name: 'marginacion',
                            },
                          ]


    return (

      <div className="div-principalDenunciaAcoso">

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

        ) : (
        <div>
          <a href="/">
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          </a>
          <MenuCentral/>
        </div>
      )}

        <div className="div-datosDemandante-denunciaAcoso">

          <h1>DATOS DE LA PERSONA QUE PRESENTA LA DENUNCIA</h1>

          <div className="div-formDatosDemandante-denunciaAcoso">
            <form class="ui form">

            <div class="field">
              <label>TIPO DE DENUNCIA</label>
              <div class="fields">
                <div class="sixteen wide field">
                  <Dropdown
                    class="ui fluid dropdown"
                    placeholder='Tipo'
                    fluid
                    selection
                    value={this.state.tipoDenuncia}
                    onChange={this.handleChangeDatos}
                    options={tiposDenuncias}
                  />
                </div>
              </div>
            </div>

              <div class="field">
                <div class="two fields">

                  <div class="field">
                    <label>NOMBRE</label>
                    <input type="text" placeholder="Nombre Completo" name="nombreDenunciante" value={this.state.nombreDenunciante} onChange={this.handleChangeDatos}/>
                  </div>

                  <div class="field">
                    <label>PUESTO O ÁREA DONDE LABORA</label>
                    <input type="text" placeholder="Donde Trabajas Actualmente?" name="puestoDenunciante" value={this.state.puestoDenunciante} onChange={this.handleChangeDatos}/>
                  </div>

                </div>
              </div>



              <div class="field">
                <div class="two fields">

                  <div class="field">
                    <label>TELEFONO</label>
                    <input type="text"  placeholder="Telefono de Contacto" name="telefonoDenunciante" value={this.state.telefonoDenunciante} onChange={this.handleChangeDatos}/>
                  </div>

                  <div class="field">
                    <label>EMAIL</label>
                    <input type="text" placeholder="Correo Electronico" name="eMailDenunciante" value={this.state.eMailDenunciante} onChange={this.handleChangeDatos}/>
                  </div>

                  <div class="field">
                    <label>DOMICILIO</label>
                    <input type="text" placeholder="Domicilio" name="domicilioDenunciante" value={this.state.domicilioDenunciante} onChange={this.handleChangeDatos}/>
                  </div>

                </div>
              </div>



               <h1>DATOS DEL SERVIDOR(A) PÚBLICO (A) CONTRA QUIEN SE PRESENTA LA DENUNCIA</h1>
               <div class="field">
                 <div class="two fields">

                   <div class="field">
                     <label>NOMBRE</label>
                     <input type="text" placeholder="Nombre Completo" name="nombreDenunciado" value={this.state.nombreDenunciado} onChange={this.handleChangeDatos}/>
                   </div>

                   <div class="field">
                     <label>PUESTO</label>
                     <input type="text" placeholder="Cargo o Puesto" name="puestoDenunciado" value={this.state.puestoDenunciado} onChange={this.handleChangeDatos}/>
                   </div>

                   <div class="field">
                     <label>DEPENDENCIA/CAMPUS DONDE LABORA</label>
                     <input type="text" placeholder="Dependencia/Campus" name="dependenciaDenunciado" value={this.state.dependenciaDenunciado} onChange={this.handleChangeDatos}/>
                   </div>


                 </div>
               </div>



               <h1>DECLARACIÓN DE LOS HECHOS</h1>
               <div class="field">
                 <div class="two fields">

                   <div class="field">
                     <label>FECHA</label>
                     <input type="text" placeholder="Fecha en que ocurrieron los hechos" name="fechaHechos" value={this.state.fechaHechos} onChange={this.handleChangeDatos}/>
                   </div>

                   <div class="field">
                     <label>HORA</label>
                     <input type="text" placeholder="Hora de los hechos" name="horaHechos" value={this.state.horaHechos} onChange={this.handleChangeDatos}/>
                   </div>

                   <div class="field">
                     <label>LUGAR</label>
                     <input type="text" placeholder="Lugar de los hechos" name="lugarHechos" value={this.state.lugarHechos} onChange={this.handleChangeDatos}/>
                   </div>

                 </div>
               </div>

               <div class="sixteen wide field">
                 <label>FRECUENCIA DE LOS HECHOS</label>
                 <input type="text" placeholder="(si fue una vez o varias veces)" name="frecuenciaHechos" value={this.state.frecuenciaHechos} onChange={this.handleChangeDatos}/>
               </div>

               <div class="field">
                <label>DESCRIBA LOS HECHOS BREVEMENTE</label>
                <textarea placeholder="Describa los hechos" name="descripcionHechos" value={this.state.descripcionHechos} onChange={this.handleChangeDatos}></textarea>
              </div>


              <h1>DATOS DE UNA PERSONA QUE HAYA SIDO TESTIGO DE LOS HECHOS</h1>

              <div class="sixteen wide field">
                <label>NOMBRE</label>
                <input type="text" placeholder="Nombre Completo" name="nombreTestigo" value={this.state.nombreTestigo} onChange={this.handleChangeDatos}/>
              </div>

              <div class="sixteen wide field">
                <label>DOMICILIO</label>
                <input type="text" placeholder="Domicilio" name="domicilioTestigo" value={this.state.domicilioTestigo} onChange={this.handleChangeDatos}/>
              </div>

              <div class="field">
                <div class="two fields">

                  <div class="field">
                    <label>TELEFONO</label>
                    <input type="text" placeholder="Telefono de contacto" name="telefonoTestigo" value={this.state.telefonoTestigo} onChange={this.handleChangeDatos}/>
                  </div>

                  <div class="field">
                    <label>EMAIL</label>
                    <input type="text" placeholder="Correo electronico" name="eMailTestigo" value={this.state.eMailTestigo} onChange={this.handleChangeDatos}/>
                  </div>


                </div>
              </div>

              <div class="field">
                <div class="two fields">

                  <div class="field">
                  <FormControlLabel
                    control={
                      <Switch
                        className="switch"
                        checked={this.state.trabajaAdministracionPublica}
                        onChange={this.handleChange}
                      />
                    }
                    label="¿TRABAJA EN LA ADMINISTRACIÓN PÚBLICA FEDERAL?"
                  />
                  </div>


                  {this.state.trabajaAdministracionPublica?(
                    <div class="field">
                      <label>Dependencia/Campus</label>
                      <input type="text" placeholder="Dependenca/Campus" name="dependenciaTestigo" value={this.state.dependenciaTestigo} onChange={this.handleChangeDatos}/>
                    </div>
                  ) : (
                    <div class="field" >
                      <label>Dependencia/Campus</label>
                      <input disabled type="text" placeholder="Correo electronico" name="dependenciaTestigo" value={this.state.dependenciaTestigo} onChange={this.handleChangeDatos}/>
                    </div>
                  )}

                </div>
              </div>

              <button className="botonSubmit" onClick={this.handleSubmit} tabindex="0">Enviar Denuncia</button>
              </form>

          </div>
        </div>



        <LinksFooter/>

      </div>
    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(DenunciaAcoso, options);


{                    /*<div class="field" >
                    <label>DEPENDENCIA</label>
                    <div class="fields">
                      <div class="sixteen wide field">
                        <select class="ui fluid dropdown" disabled>
                          <option value="direccionGeneral">Direccion General</option>
                          <option value="arandas">Arandas</option>
                          <option value="chapala">Chapala</option>
                          <option value="cocula">Cocula</option>
                          <option value="elGrullo">El Grullo</option>
                          <option value="laGuerta">La Guerta</option>
                          <option value="lagos">Lagos de Moreno</option>
                          <option value="mascota">Mascota</option>
                          <option value="vallarta">Puerta Vallarta</option>
                          <option value="tala">Tala</option>
                          <option value="tamazula">Tamazula</option>
                          <option value="tequila">Tequila</option>
                          <option value="zapopan">Zapopan</option>
                          <option value="zapotlanejo">Zapotlanejo</option>
                        </select>
                      </div>
                    </div>
                  </div>*/}
