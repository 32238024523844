import React, { Component } from 'react';
import Add from '@material-ui/icons/AddCircle';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen'

import Typist from 'react-typist';

import 'rodal/lib/rodal.css';
import './styles/noticieroComponent.css'

import Noticias from './noticieroData.js'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'


class TimeLineNoticiasItem extends Component{


  constructor(props){
    super(props);
    this.state={
      datos:''
    }
    this.clickNoticiaTimeLine = this.clickNoticiaTimeLine.bind(this);
  }

  componentWillMount(){
    this.setState({

      datos:this.props.datos

    })
  }

  clickNoticiaTimeLine(){
    this.props.callBack(this.props.datos)

  }



  render(){
    return(

      <div onClick={this.clickNoticiaTimeLine} className="div-timeLineNoticiasItem">
        <img src={this.state.datos.imagenes[0]}/>
        <h2>{this.state.datos.titulo}</h2>
      </div>

    );
  }
}

class NoticieroComponent extends Component {

  constructor(){
    super();
    this.state={
      timeLineNoticiasArray:'',
      titulo:'',
      fechaLugar:'',
      imagenes:'',
      id:'',
      parrafosNoticia:''
    }
  }



  componentWillMount(){
    var timeLineNoticasVar = Noticias();

    var noticiaLocalString = localStorage.getItem("noticia");
    var noticiaLocalParse = JSON.parse(noticiaLocalString);

    var tituloLocal = noticiaLocalParse.titulo;
    var fechaLugarLocal = noticiaLocalParse.fechaLugar;
    var imagenesLocal = noticiaLocalParse.imagenes;
    var id = noticiaLocalParse.id;
    var parrafosNoticiaLocal = JSON.parse(noticiaLocalParse.parrafosNoticia);

    console.log("aqui se muestra lo recibido: "+imagenesLocal[0]);


    this.setState({
      timeLineNoticiasArray:timeLineNoticasVar,
      titulo:tituloLocal,
      fechaLugar:fechaLugarLocal,
      imagenes:imagenesLocal,
      parrafosNoticia:parrafosNoticiaLocal
    })

  }

  callBack(noticia){

    this.setState({

      titulo:noticia.titulo,
      fechaLugar:noticia.fechaLugar,
      imagenes:noticia.imagenes,
      parrafosNoticia:noticia.parrafosNoticia
    })
  }


  render() {

    return (
      <div className="div-principalNoticieroComponent">
        {this.props.isMobile() || this.props.isTablet()?(
          <div>
            <MenuCentralMovil/>
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
          </div>

          ) : (
          <div>
            <a href="/">
              <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
            </a>
            <MenuCentral/>
          </div>
        )}

        <div className="div-noticiasComponent">

          <div className="div-contenidoNoticia">
            <h1>{this.state.titulo}</h1>

            <img src={this.state.imagenes[0]}/>

            {this.state.parrafosNoticia.map((it)=>(
              <p>{it.parrafo}</p>
            ))}

          </div>

          <div className="div-timeLineNoticias">
            <h1>NOTICIAS RELACIONADAS</h1>
            <ul>
              {this.state.timeLineNoticiasArray.map((it)=>(
                <li>
                  <TimeLineNoticiasItem callBack={this.callBack.bind(this)} datos={it}/>
                </li>
              ))}
            </ul>
          </div>


        </div>

        <LinksFooter/>
      </div>

    );
  }
}
const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(NoticieroComponent, options);
