import React, { Component } from 'react';

import ResponsiveMenu from 'react-responsive-navbar';
import './styles/componentePruebas.css'

class Pruebas extends Component {

  constructor(props) {
     super(props);
     this.state = {
       addClass: false
     }
   }

  toggle() {
    this.setState({
      addClass: !this.state.addClass
    });
  }

  myFunction(){
    var x = this.myTopnav;
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }


  render() {

    let boxClass = ["topnav"];
    if(this.state.addClass) {
      boxClass.push('responsive');
    }

    return (

      <div className="div-principal-pruebas">
        <img className="nombreTECMM" src="http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg"/>
        <div className={boxClass.join(' ')} >
          <div class="dropdown">
            <a class="dropbtn">SOMOS TECMM</a>
            <div class="dropdown-content">
              <a href="#quienesSomos">Quiénes Somos</a>
              <a href="#rectoria">Rectoria</a>
              <a href="#bienvenida">Bienvenida</a>
              <a href="#modeloAcademico">Modelo Académico</a>
            </div>
          </div>
          <a href="http://controlescolar.tecmm.edu.mx">ADMISIÓN</a>
          <a href="#vidaEstudiantil">VIDA ESTUDIANTIL</a>
          <a href="http://vinculacion.tecmm.edu.mx/">VINCULACIÓN</a>
          <a href="http://www.areaacademica.tecmm.edu.mx/">ÁREA ACADÉMICA</a>
          <a href="#contacto">CONTACTO</a>
          <a href="#igualdad">SGIG</a>
          <a href="#quejasYdenuncias">QUEJAS Y DENUNCIAS</a>



          <a class="icon" onClick={this.toggle.bind(this)}>a</a>
        </div>
      </div>
    );
  }
}

export default Pruebas;
