import React, { Component } from 'react';
import Add from '@material-ui/icons/AddCircle';
import {withGetScreen} from 'react-getscreen'

import Typist from 'react-typist';
import ReactImageMagnify from 'react-image-magnify';
import YouTube from 'react-youtube';
import Rodal from 'rodal';

import 'rodal/lib/rodal.css';
import './styles/rectoria.css'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'
import bannerTNM from '../header-nuevo2.jpg'

class RectoriaItem2 extends Component{


  constructor(props){
    super(props);
    this.state={

    }
  }

  render(){
    return(

      <div id="directivos" class="ui card">
        <div class="image">
          <img src={this.props.image}/>
        </div>
        <div class="content">
          <a class="header">{this.props.nombre}</a>

          <div class="description">
            {this.props.cargo}
          </div>
        </div>
        <div class="extra content">
          <a href={this.props.mailTo}>
            <i class="envelope icon"></i>
            {this.props.correo}
          </a>
        </div>
      </div>

    );
  }
}


class Rectoria extends Component {

  constructor(){
    super();
    this.state={
      directoresArray:[
        {nombre: 'Lic. Jorge Capilla Pacheco', image:"http://tecmm.edu.mx/imagesReact/images/directores/no-foto.jpg", cargo: 'Comisario Publico', correo:'jorge.capilla@tecmm.edu.mx', mailTo:'mailto:jorge.capilla@tecmm.edu.mx'},
        {nombre: 'Mtra. María del Carmen Calva Tapia', image:"http://tecmm.edu.mx/imagesReact/images/directores/Carmen-Calva.jpg", cargo: 'Directora de Extension y Vinculacion', correo:'carmen.calva@tecmm.edu.mx', mailTo:'mailto:carmen.calva@tecmm.edu.mx'},
        {nombre: 'Mtro. Martín Villaseñor Flores', image:"http://tecmm.edu.mx/imagesReact/images/directores/Martin-Villasenor.jpg", cargo: 'Director de Area Academica, Investigacion e Innovacion', correo:'martin.villasenor@tecmm.edu.mx', mailTo:'mailto:martin.villasenor@tecmm.edu.mx'},
        {nombre: 'Dr. Luis Fernando González Aldrete', image:"http://tecmm.edu.mx/imagesReact/images/directores/Luis-Fernando-Gonzalez-Aldrete.jpg", cargo: 'Director de Planeacion y Desarrollo', correo:'luisfernando.gonzalez@tecmm.edu.mx', mailTo:'mailto:luisfernando.gonzalez@tecmm.edu.mx'},
        {nombre: 'L.C.P. Ricardo Ramos Dueñas', image:"http://tecmm.edu.mx/imagesReact/images/directores/Lic-Ricardo.jpg", cargo: 'Director de Administracion y Finanzas', correo:'ricardo.ramos@tecmm.edu.mx', mailTo:'mailto:ricardo.ramos@tecmm.edu.mx'},
        {nombre: 'Lic. Juan Pablo Cerrillo Hernández', image:"http://tecmm.edu.mx/imagesReact/images/directores/Juan-Pablo-Cerrillo-Hernandez.jpg", cargo: 'Campus Arandas', correo:'juan.cerrillo@tecmm.edu.mx', mailTo:'mailto:juan.cerrillo@tecmm.edu.mx'},
        {nombre: 'Mtro. Luis Eduardo Jiménez Herrera', image:"http://tecmm.edu.mx/imagesReact/images/directores/Luis-Eduardo-Jimenez-Herrera.jpg", cargo: 'Campus Chapala', correo:'luis.jimenez@tecmm.edu.mx', mailTo:'mailto:luis.jimenez@tecmm.edu.mx'},
        {nombre: 'Ing. Sergio Arturo Martínez Méndez', image:"http://tecmm.edu.mx/imagesReact/images/directores/Sergio-Arturo-Martinez-Mendez.jpg", cargo: 'Campus Cocula', correo:'sergio.martinez@tecmm.edu.mx', mailTo:'mailto:sergio.martinez@tecmm.edu.mx'},
        {nombre: 'Mtro. Roberto Durán Michel', image:"http://tecmm.edu.mx/imagesReact/images/directores/Roberto-Duran-Michel.jpg", cargo: 'Campus El Grullo', correo:'roberto.duran@tecmm.edu.mx', mailTo:'mailto:roberto.duran@tecmm.edu.mx'},
        {nombre: 'Ing. Gualberto Castro Moreno', image:"http://tecmm.edu.mx/imagesReact/images/directores/Gualberto-Moreno-Castro.jpg", cargo: 'Campus La Huerta', correo:'gualberto.castro@tecmm.edu.mx', mailTo:'mailto:gualberto.castro@tecmm.edu.mx'},
        {nombre: 'Dra. Ma. Eugenia Amador Murguía', image:"http://tecmm.edu.mx/imagesReact/images/directores/Maria-Eugenia-Amador-Murguia.jpg", cargo: 'Campus Lagos de Moreno', correo:'maria.amador@tecmm.edu.mx', mailTo:'mailto:maria.amador@tecmm.edu.mx'},
        {nombre: 'Mtro. Emmanuel Pablo Saldaña Castillón', image:"http://tecmm.edu.mx/imagesReact/images/directores/Emmanuel-Pablo-Saldana-Castillon.jpg", cargo: 'Campus Mascota', correo:'emmanuel.saldana@tecmm.edu.mx', mailTo:'mailto:emmanuel.saldana@tecmm.edu.mx'},
        {nombre: 'Dr. Ernesto Rosales Castañeda', image:"http://tecmm.edu.mx/imagesReact/images/directores/Ernesto-Rosales-Castaneda.jpg", cargo: 'Campus Tequila', correo:'ernesto.rosales@tecmm.edu.mx', mailTo:'mailto:ernesto.rosales@tecmm.edu.mx'},
        {nombre: 'Mtra. Gloria Luz Rodríguez Gil', image:"http://tecmm.edu.mx/imagesReact/images/directores/Gloria-Luz-Rodriguez-Gil.jpg", cargo: 'Campus Tala', correo:'gloria.rodriguez@tecmm.edu.mx', mailTo:'mailto:gloria.rodriguez@tecmm.edu.mx'},
        {nombre: 'M.C. Felipe Alfonso Ordoñez García', image:"http://tecmm.edu.mx/imagesReact/images/directores/Felipe-Alfonso-Ordonez-Garcia.jpg", cargo: 'Campus Tamazula', correo:'alfonso.ordonez@tecmm.edu.mx', mailTo:'mailto:alfonso.ordonez@tecmm.edu.mx'},
        {nombre: 'Mtro. Oscar Daniel Zamora Cuevas', image:"http://tecmm.edu.mx/imagesReact/images/directores/Oscar-Daniel-Zamora-Cuevas.jpg", cargo: 'Campus Puerto Vallarta', correo:'daniel.zamora@tecmm.edu.mx', mailTo:'mailto:daniel.zamora@tecmm.edu.mx'},
        {nombre: 'Dr. Juan Antonio González Arechiga Ramírez Wiella', image:"http://tecmm.edu.mx/imagesReact/images/directores/Juan-Antonio-Gonzalez-Arechiga-Ramirez-Wiella.jpg", cargo: 'Campus Zapopan', correo:'antonio.gonzalezarechiga@tecmm.edu.mx', mailTo:'mailto:antonio.gonzalezarechiga@tecmm.edu.mx'},
        {nombre: 'Mtro. Héctor Dávalos Tinajero', image:"http://tecmm.edu.mx/imagesReact/images/directores/Hector-Davalos-Tinajero.jpg", cargo: 'Campus Zapotlanejo', correo:'hector.davalos@tecmm.edu.mx', mailTo:'mailto:hector.davalos@tecmm.edu.mx'},
      ],
      directoresArrayMobile:[
        {nombre: 'Lic. Maria Elena Zendejas Montaño', image:"http://tecmm.edu.mx/imagesReact/images/directores/no-foto.jpg", cargo: 'Comisario Publico', correo:'elena.zendejas@tecmm.edu.mx'},
        {nombre: 'Mtra. María del Carmen Calva Tapia', image:"http://tecmm.edu.mx/imagesReact/images/directores/Carmen-Calva.jpg", cargo: 'Directora de Extension y Vinculacion', correo:'carmen.calva@tecmm.edu.mx'},
        {nombre: 'Mtro. Martín Villaseñor Flores', image:"http://tecmm.edu.mx/imagesReact/images/directores/Martin-Villasenor.jpg", cargo: 'Director de Area Academica, Investigacion e Innovacion', correo:'martin.villasenor@tecmm.edu.mx'},
        {nombre: 'Dr. Luis Fernando González Aldrete', image:"http://tecmm.edu.mx/imagesReact/images/directores/Luis-Fernando-Gonzalez-Aldrete.jpg", cargo: 'Director de Planeacion y Desarrollo', correo:'luisfernando.gonzalez@tecmm.edu.mx'},
        {nombre: 'L.C.P. Ricardo Ramos Dueñas', image:"http://tecmm.edu.mx/imagesReact/images/directores/Lic-Ricardo.jpg", cargo: 'Director de Administracion y Finanzas', correo:'ricardo.ramos@tecmm.edu.mx'},
        {nombre: 'Lic. Juan Pablo Cerrillo Hernández', image:"http://tecmm.edu.mx/imagesReact/images/directores/Juan-Pablo-Cerrillo-Hernandez.jpg", cargo: 'Campus Arandas', correo:'juan.cerrillo@tecmm.edu.mx'},
        {nombre: 'Mtro. Luis Eduardo Jiménez Herrera', image:"http://tecmm.edu.mx/imagesReact/images/directores/Luis-Eduardo-Jimenez-Herrera.jpg", cargo: 'Campus Chapala', correo:'luis.jimenez@tecmm.edu.mx'},
        {nombre: 'Ing. Sergio Arturo Martínez Méndez', image:"http://tecmm.edu.mx/imagesReact/images/directores/Sergio-Arturo-Martinez-Mendez.jpg", cargo: 'Campus Cocula', correo:'sergio.martinez@tecmm.edu.mx'},
        {nombre: 'Mtro. Roberto Durán Michel', image:"http://tecmm.edu.mx/imagesReact/images/directores/Roberto-Duran-Michel.jpg", cargo: 'Campus El Grullo', correo:'roberto.duran@tecmm.edu.mx'},
        {nombre: 'Ing. Gualberto Castro Moreno', image:"http://tecmm.edu.mx/imagesReact/images/directores/Gualberto-Moreno-Castro.jpg", cargo: 'Campus La Huerta', correo:'gualberto.castro@tecmm.edu.mx'},
        {nombre: 'Dra. Ma. Eugenia Amador Murguía', image:"http://tecmm.edu.mx/imagesReact/images/directores/Maria-Eugenia-Amador-Murguia.jpg", cargo: 'Campus Lagos de Moreno', correo:'maria.amador@tecmm.du.mx'},
        {nombre: 'Mtro. Emmanuel Pablo Saldaña Castillón', image:"http://tecmm.edu.mx/imagesReact/images/directores/Emmanuel-Pablo-Saldana-Castillon.jpg", cargo: 'Campus Mascota', correo:'emmanuel.saldana@tecmm.edu.mx'},
        {nombre: 'Dr. Ernesto Rosales Castañeda', image:"http://tecmm.edu.mx/imagesReact/images/directores/Ernesto-Rosales-Castaneda.jpg", cargo: 'Campus Tequila', correo:'ernesto.rosales@tecmm.edu.mx'},
        {nombre: 'Mtra. Gloria Luz Rodríguez Gil', image:"http://tecmm.edu.mx/imagesReact/images/directores/Gloria-Luz-Rodriguez-Gil.jpg", cargo: 'Campus Tala', correo:'gloria.rodriguez@tecmm.edu.mx'},
        {nombre: 'M.C. Felipe Alfonso Ordoñez García', image:"http://tecmm.edu.mx/imagesReact/images/directores/Felipe-Alfonso-Ordonez-Garcia.jpg", cargo: 'Campus Tamazula', correo:'alfonso.ordonez@tecmm.edu.mx', mailTo:'mailto:alfonso.ordonez@tecmm.edu.mx'},
        {nombre: 'Mtro. Oscar Daniel Zamora Cuevas', image:"http://tecmm.edu.mx/imagesReact/images/directores/Oscar-Daniel-Zamora-Cuevas.jpg", cargo: 'Campus Puerto Vallarta', correo:'oscar.zamora@tecmm.edu.mx'},
        {nombre: 'Dr. Juan Antonio González Arechiga Ramírez Wiella', image:"http://tecmm.edu.mx/imagesReact/images/directores/Juan-Antonio-Gonzalez-Arechiga-Ramirez-Wiella.jpg", cargo: 'Campus Zapopan', correo:'antonio.gonzalezarechiga@tecmm.edu.mx'},
        {nombre: 'Mtro. Héctor Dávalos Tinajero', image:"http://tecmm.edu.mx/imagesReact/images/directores/Hector-Davalos-Tinajero.jpg", cargo: 'Campus Zapotlanejo', correo:'hector.davalos@tecmm.edu.mx'},
      ]
    }
  }


  render() {

    return (

      <div className="div-principalRectoria">

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

        ) : (
        <div>
          <a href="/">
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          </a>
          <MenuCentral/>
        </div>
      )}

        <div className="div-directores">
          <h1>
            Rectoria

          </h1>


          <div id="DrSalgado" class="ui card">
            <div class="image">
              <img src='http://tecmm.edu.mx/imagesReact/images/directores/Hector-Salgado.jpg'/>
            </div>
            <div class="content">
              <a id="header-rectoria" class="header">Dr. Héctor Enrique Salgado Rodríguez</a>

              <div class="description">
                Director General
              </div>
            </div>
            <div class="extra content">
              <a href='mailto:direccion.general@tecmm.edu.mx'>
                <i class="envelope icon"></i>
                direccion.general@tecmm.edu.mx
              </a>
            </div>
          </div>


          {this.props.isMobile() || this.props.isTablet()?(
            <div className="grid-container-directores" class="ui grid container">
              {this.state.directoresArray.map((it)=>(
                <RectoriaItem2 image={it.image} nombre={it.nombre} cargo={it.cargo} correo={it.correo} mailTo={it.mailTo}/>
              ))}
            </div>
          ):(
            <div className="grid-container-directores" class="ui grid container">

              {this.state.directoresArray.map((it)=>(
                <RectoriaItem2 image={it.image} nombre={it.nombre} cargo={it.cargo} correo={it.correo} mailTo={it.mailTo}/>
              ))}
            </div>
          )}


        </div>

      <LinksFooter/>

      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Rectoria, options);
