import React, { Component } from 'react';
import {withGetScreen} from 'react-getscreen'
import {Route, Link, HashRouter, BrowserRouter, Switch,Router} from 'react-router-dom'
import Carousel from './components/carousel.js';
import DescubreTECMM from './components/descubreTECMM.js';
import Noticiero from './components/noticiero.js';
import LinksFooter from './components/linksFooter.js';
import MenuCentral from './components/menuCentral.js'
import MenuCentralMovil from './components/menuCentralMovil.js'
import OfertaAcademicaLanding from './components/ofertaAcademicaLanding.js'

import './landing.css';


import bannerTNM from './header-nuevo2.jpg'


class Landing extends Component {

  constructor(){
    super();
    this.state={
      selected: 0,
    }
  }




  render() {

    const facebookLogo = 'http://tecmm.edu.mx/imagesReact/images/menuRedesSociales/facebook.png';
    const twitterLogo = 'http://tecmm.edu.mx/imagesReact/images/menuRedesSociales/twitter.png';
    const instagramLogo = 'http://tecmm.edu.mx/imagesReact/images/menuRedesSociales/instagram.png';
    const youtubeLogo = 'http://tecmm.edu.mx/imagesReact/images/menuRedesSociales/youtube.png'


    if(this.props.isMobile() || this.props.isTablet()){
      return(

          <div className="div-principal-landing">

            <MenuCentralMovil/>

            <img className="img-bannerTNM" src={bannerTNM}/>

            <img className="img-nomreTECMM" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>

            <div className="div-carousel">
              <Carousel/>
            </div>

            <div className="div-landing-botones">

              <Link to="/ofertaAcademica">
                <button  className="botonOferta">OFERTA<br/>ACADÉMICA</button>
              </Link>

              <Link to={'/campus'}>
                <button className="botonCampus">RED DE CAMPUS<br/>EN JALISCO</button>
              </Link>

              <form action="http://controlescolar.tecmm.edu.mx/">
                <button className="botonEscolares">SERVICIOS<br/>ESCOLARES</button>
              </form>

            </div>

            <div className="div-noticiero">
              <Noticiero/>
            </div>

            {/*<OfertaAcademicaLanding/>*/}

           <div className="div-cardsInteres">
              <DescubreTECMM/>
            </div>

            <div className="div-linksFooter">
              <LinksFooter/>
            </div>


          </div>
      );
    }

    return (

      <div className="div-principal-landing">


        <img className="img-bannerTNM" src={bannerTNM}/>

        <div className="div-menuCentral">
          <MenuCentral/>

        </div>

        <div className="redes-lateral">

          <a href="https://www.facebook.com/tecmm">
            <img src={facebookLogo}/>
          </a>

          <a href="https://twitter.com/mm_tec">
            <img src={twitterLogo}/>
          </a>

          <a href="https://www.instagram.com/tecmm">
            <img src={instagramLogo}/>
          </a>

          <a href="https://www.youtube.com/channel/UCUfv6a53VuKcEwo-XgF_mkw/featured">
            <img src={youtubeLogo}/>
          </a>

        </div>

        <div className="div-carousel">
          <Carousel/>
        </div>

        <div className="div-noticiero">
          <Noticiero/>
        </div>

       {/*<OfertaAcademicaLanding/>*/}

       <div className="div-cardsInteres">
          <DescubreTECMM/>
        </div>

        <div className="div-linksFooter">
          <LinksFooter/>
        </div>


      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(Landing, options);
