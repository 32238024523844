
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen';
import Typist from 'react-typist';


import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'

import './styles/quienesSomos.css';
import bannerTNM from '../header-nuevo2.jpg'



class QuienesSomos extends Component {



  constructor(props){
    super(props);
    this.state = {

    }
  }


  render() {

  const QuienesSomosImage1 = 'http://tecmm.edu.mx/imagesReact/images/quienesSomos/quienesSomos1.jpg'
  const QuienesSomosImage2 = 'http://tecmm.edu.mx/imagesReact/images/quienesSomos/quienesSomos2.jpg'
  const QuienesSomosImage3 = 'http://tecmm.edu.mx/imagesReact/images/quienesSomos/quienesSomos3.jpg'


    if(this.props.isMobile()){
      return(
        <div className="div-principalQuienesSomos">

          <MenuCentralMovil/>

          <img className="img-bannerTNM-quienesSomos" src={bannerTNM}/>

          <div className="div-contenido-quienesSomos">

              <div className="div-portada-quienesSomos">
                <h1>
                  ¿QUIENES SOMOS?
                </h1>
              </div>


              <p>Fundado el 23 de agosto del 2016, por decreto de creación número
                  25535/LX/15, aprobado por el H. Congreso del Estado de Jalisco, crea el
                  Instituto Tecnológico José Mario Molina Pasquel y Henríquez como organismo
                  público descentralizado del Gobierno del Estado con personalidad jurídica
                  y patrimonio propio, el cual tiene la finalidad de prestar servicio de educación
                  superior tecnológica en el Estado de Jalisco, adscrita al Tecnológico
                  Nacional de México y sectorizada a la Secretaría de Innovación Ciencia y
                  Tecnología.</p>

              <p>El Instituto Tecnológico José Mario Molina Pasquel y Henríquez reconocido
                por sus siglas TecMM. Nombre asignado en honor al Ingeniero Químico galardonado
                en el año 1995 con el Premio Nobel de Química por su investigación
                de los estudios relacionados con el impacto de la Capa de Ozono.</p>


              <h1 className="h1-subtitulo">INICIATIVA</h1>

                <img src={QuienesSomosImage1}/>

                <div className="div-iniciativa">

                  <p>La iniciativa de crear el TecMM, fue unificar a los 13 Institutos Tecnológicos
                    Superiores, en un solo Organismo Público descentralizado, con una Dirección
                    general y 13 Campus en el Estado de Jalisco. Con el propósito de
                    eficientar la estructura académica y administrativa, respondiendo a los objetivos
                    del Plan Nacional de Desarrollo y del Plan Estatal de Desarrollo de Jalisco,
                    que establece el compromiso de un “México con educación de calidad”. <br/><br/>

                    Nuestra Institución es heredera de una brillante y firme historia de vida institucional
                    con 20 años de creación en el Estado de Jalisco, adscrito al
                    Tecnológico Nacional de México, siendo el más grande de Iberoamérica con
                    600,000 mil estudiantes, 254 institutos en todo el país, teniendo presencia
                    en más de 600 localidades en los 32 estados del país.</p>

                </div>


              <h1  className="h1-subtitulo">RED TECMM</h1>

                <img src={QuienesSomosImage2}/>
                <div className="div-iniciativa">

                  <p>En Jalisco somos una red de trece campus y doce extensiones, contamos con
                    una cobertura de Educación Superior en once de las doce regiones del
                    Estado de Jalisco con más de 15,000 estudiantes, que nos convierte en la segunda
                    Institución en el Estado lo que representa el 9.74 por ciento de la
                    matrícula de Educación Superior, con 16 ingenierías, 5 licenciaturas, y 2 posgrados.<br/><br/>

                    Los 13 Campus del Estado de Jalisco del Instituto Tecnológico José Mario
                    Molina Pasquel y Henríquez son: Arandas, Chapala, Cocula, El Grullo, La
                    Huerta, Lagos de Moreno, Mascota, Puerto Vallarta, Tala, Tamazula, Tequila,
                    Zapopan y Zapotlanejo.<br/><br/>

                    Además contamos con 12 extensiones ubicadas en:
                    Cocula: Tecolotlán y Atemajac de Brizuela
                    La Huerta: Villa Purificación, Tomatlán y Cihuatlán
                    Tala: Ixtlahuacán, Cuquío, Huejuquilla y Totatiche
                    Mascota: Tenamaxtlán
                    Lagos de Moreno: Ojuelos y Jalostotitlán</p>
                </div>

              <h1 className="h1-subtitulo">MISIÓN</h1>
                <p className="p-mision">“Brindar educación integral de clase mundial para formar profesionistas competitivos,
                 innovadores y emprendedores y satisfacer las demandas de desarrollo
                 científico y tecnológico del sector productivo en cada una de las regiones del
                 estado de Jalisco con proceso de investigación aplicada y transferencia de tecnología”.</p>



              <h1 className="h1-subtitulo">VISIÓN</h1>
                <p className="p-vision">“Ser reconocido por su liderazgo en la economía del conocimiento nacional,
                formando los profesionistas más talentosos, innovadores y emprendedores del país y
                tener los más altos índices de investigación e innovación tecnológica, así como de la
                producción y transferencia de propiedad intelectual entre las instituciones de educación
                superior de México”. </p>

              <h1 className="h1-subtitulo">VALORES</h1>
                <div >
                  <img src={QuienesSomosImage3}/>
                  <p>Bienestar Social. Mejorar el acceso, la cobertura y la calidad de la educación superior, reducir el rezago educativo y promover la equidad en las oportunidades educativas.<br/><br/>
                    Sustentabilidad. Compromiso con la economía y la ecología de sostenimiento a lo largo del tiempo sin agotar los recursos o perjudicar el medio ambiente.<br/><br/>
                    Integridad. La capacidad de actuar en consecuencia con lo que se dice o lo que se considera que es importante, tiene todas sus partes enteras y no afecta los intereses de las personas ni la institución.<br/><br/>
                    Innovación. De manera continua buscamos introducir en todos los ámbitos del quehacer de nuestro Instituto, cambios que representen una mejora, un progreso y sobre todo un mayor valor para los receptores de nuestros servicios y productos (clientes internos y externos) y de todo nuestro ecosistema.<br/><br/>
                    Competitividad. Es la cualidad con la cual se busca la realización de los objetivos y generar los mejores resultados educativos, generando conocimiento para el desarrollo de las personas y su entorno.<br/><br/>
                    Emprendimiento. Generar actividades y estrategias para establecer el ambiente emprendedor, estimulando la iniciativa de los estudiantes para desarrollar un proyecto de negocio, generando el sentido de emprendimiento y generación de ideas creativas viables, para que se conviertan en una realidad.<br/><br/> </p>
                </div>
          </div>



          <LinksFooter/>

        </div>
      );
    }



    return (
      <div className="div-principalQuienesSomos">

        <a href="/">
          <img className="img-bannerTNM-quienesSomos" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
        </a>

        <div className="MenuCentral">
          <MenuCentral/>
        </div>

        <div className="div-portadaQuienesSomos">
          <h1>
            <Typist>
              ¿Quienes Somos?
            </Typist>
          </h1>
        </div>

        <div className="div-contenidoQuienesSomos">


          <p className="p-quienesSomos">Fundado el 23 de agosto del 2016, por decreto de creación número
              25535/LX/15, aprobado por el H. Congreso del Estado de Jalisco, crea el
              Instituto Tecnológico José Mario Molina Pasquel y Henríquez como organismo
              público descentralizado del Gobierno del Estado con personalidad jurídica
              y patrimonio propio, el cual tiene la finalidad de prestar servicio de educación
              superior tecnológica en el Estado de Jalisco, adscrita al Tecnológico
              Nacional de México y sectorizada a la Secretaría de Innovación Ciencia y
              Tecnología.<br/><br/>

              El Instituto Tecnológico José Mario Molina Pasquel y Henríquez reconocido
              por sus siglas TecMM. Nombre asignado en honor al Ingeniero Químico galardonado
              en el año 1995 con el Premio Nobel de Química por su investigación
              de los estudios relacionados con el impacto de la Capa de Ozono.</p>


          <h1>INICIATIVA</h1>
            <div className="div-iniciativa">

              <p>La iniciativa de crear el TecMM, fue unificar a los 13 Institutos Tecnológicos
                Superiores, en un solo Organismo Público descentralizado, con una Dirección
                general y 13 Campus en el Estado de Jalisco. Con el propósito de
                eficientar la estructura académica y administrativa, respondiendo a los objetivos
                del Plan Nacional de Desarrollo y del Plan Estatal de Desarrollo de Jalisco,
                que establece el compromiso de un “México con educación de calidad”. <br/><br/>

                Nuestra Institución es heredera de una brillante y firme historia de vida institucional
                con 20 años de creación en el Estado de Jalisco, adscrito al
                Tecnológico Nacional de México, siendo el más grande de Iberoamérica con
                600,000 mil estudiantes, 254 institutos en todo el país, teniendo presencia
                en más de 600 localidades en los 32 estados del país.</p>

              <img className="img-contenidoQuienesSomos" src={QuienesSomosImage1}/>

            </div>



          <h1>RED TECMM</h1>

            <div className="div-iniciativa">

              <p>En Jalisco somos una red de trece campus y doce extensiones, contamos con
                una cobertura de Educación Superior en once de las doce regiones del
                Estado de Jalisco con más de 15,000 estudiantes, que nos convierte en la segunda
                Institución en el Estado lo que representa el 9.74 por ciento de la
                matrícula de Educación Superior, con 16 ingenierías, 5 licenciaturas, y 2 posgrados.<br/><br/>

                Los 13 Campus del Estado de Jalisco del Instituto Tecnológico José Mario
                Molina Pasquel y Henríquez son: Arandas, Chapala, Cocula, El Grullo, La
                Huerta, Lagos de Moreno, Mascota, Puerto Vallarta, Tala, Tamazula, Tequila,
                Zapopan y Zapotlanejo.<br/><br/>

                Además contamos con 12 extensiones ubicadas en:
                Cocula: Tecolotlán y Atemajac de Brizuela
                La Huerta: Villa Purificación, Tomatlán y Cihuatlán
                Tala: Ixtlahuacán, Cuquío, Huejuquilla y Totatiche
                Mascota: Tenamaxtlán
                Lagos de Moreno: Ojuelos y Jalostotitlán</p>

              <img className="img-contenidoQuienesSomos" src={QuienesSomosImage2}/>

            </div>




          <h1>MISIÓN</h1>
            <p className="p-mision">“Brindar educación integral de clase mundial para formar profesionistas competitivos,
             innovadores y emprendedores y satisfacer las demandas de desarrollo
             científico y tecnológico del sector productivo en cada una de las regiones del
             estado de Jalisco con proceso de investigación aplicada y transferencia de tecnología”.</p>



          <h1>VISIÓN</h1>
            <p className="p-vision">“Ser reconocido por su liderazgo en la economía del conocimiento nacional,
            formando los profesionistas más talentosos, innovadores y emprendedores del país y
            tener los más altos índices de investigación e innovación tecnológica, así como de la
            producción y transferencia de propiedad intelectual entre las instituciones de educación
            superior de México”. </p>


          <h1>VALORES</h1>
            <div className="div-iniciativa">

              <p>Bienestar Social. Mejorar el acceso, la cobertura y la calidad de la educación superior, reducir el rezago educativo y promover la equidad en las oportunidades educativas.<br/><br/>
                Sustentabilidad. Compromiso con la economía y la ecología de sostenimiento a lo largo del tiempo sin agotar los recursos o perjudicar el medio ambiente.<br/><br/>
                Integridad. La capacidad de actuar en consecuencia con lo que se dice o lo que se considera que es importante, tiene todas sus partes enteras y no afecta los intereses de las personas ni la institución.<br/><br/>
                Innovación. De manera continua buscamos introducir en todos los ámbitos del quehacer de nuestro Instituto, cambios que representen una mejora, un progreso y sobre todo un mayor valor para los receptores de nuestros servicios y productos (clientes internos y externos) y de todo nuestro ecosistema.<br/><br/>
                Competitividad. Es la cualidad con la cual se busca la realización de los objetivos y generar los mejores resultados educativos, generando conocimiento para el desarrollo de las personas y su entorno.<br/><br/>
                Emprendimiento. Generar actividades y estrategias para establecer el ambiente emprendedor, estimulando la iniciativa de los estudiantes para desarrollar un proyecto de negocio, generando el sentido de emprendimiento y generación de ideas creativas viables, para que se conviertan en una realidad.<br/><br/> </p>
              <img className="img-contenidoQuienesSomos" src={QuienesSomosImage3}/>

            </div>
        </div>



        <LinksFooter/>

      </div>
    );
  }
}

const options = {mobileLimit: 420, tabletLimit: 800}
export default withGetScreen(QuienesSomos, options);
