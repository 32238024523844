import React, { Component } from 'react';
import Add from '@material-ui/icons/AddCircle';
import {withGetScreen} from 'react-getscreen'
import { Grid, Image } from 'semantic-ui-react'

import Typist from 'react-typist';
import Rodal from 'rodal';

import 'rodal/lib/rodal.css';
import './styles/vidaEstudiantil.css'

import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil.js'
import LinksFooter from './linksFooter.js'



class VidaEstudiantil extends Component {

  constructor(){
    super();
    this.state={
      deportesArray:[
        {nombre: 'FUTBOL', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/futbol.JPG"},
        {nombre: 'BASQUETBOL', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/basketball.JPG"},
        {nombre: 'VOLEIBOL DE SALA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/voley1.JPG"},
        {nombre: 'VOLEIBOL DE PLAYA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/voleibolPlaya.jpg"},
        {nombre: 'BÉISBOL', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/beisbol.jpg"},
      ],
      deportesIndividualesArray:[
        {nombre: 'AJEDREZ', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/ajedrez.JPG"},
        {nombre: 'ATLETISMO', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/atletismo.jpg"},
        {nombre: 'NATACIÓN', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/natacion.jpg"},
        {nombre: 'TENIS', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/tenis.jpg"},
        {nombre: 'ESCALA DEPORTIVA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/escalaDeportiva.jpg"},
      ],
      arteYCulturaArray:[
        {nombre: 'DANZA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/danza.jpg"},
        {nombre: 'MÚSICA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/musica.jpg"},
        {nombre: 'CREACIÓN LITERARIA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/creacionLiteraria.jpg"},
        {nombre: 'SPELLING BEE', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/spellingBee.jpg"},
        {nombre: 'FOTOGRAFÍA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/fotografia.jpg"},
        {nombre: 'PINTURA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/pintura.JPG"},
      ],
      civicosArray:[
        {nombre: 'ESCOLTA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/escolta.jpg"},
        {nombre: 'BANDA DE GUERRA', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/bandaDeGuerra.JPG"},
      ],
      asocidadosArray:[
        {nombre: 'Tochito', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/tochito1.JPG"},
        {nombre: 'Softbol', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/softbol.jpg"},
        {nombre: 'HandBall', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Rugby Sevens', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Tenis de mesa', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Badminton', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Boxeo universitario', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Esgrima', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Gimnasia aerobica', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Judo', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Karate Do', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Levantamiento de pesas', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Lucha universitaria', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Taekwondo', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},
        {nombre: 'Tiro de arco', image:"http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"},

      ],
      contactosArray:[
        {nombre: 'C. Francisco Javier Gutiérrez Torres',campus:'Arandas', linkCorreo:'mailto:francisco.gutierrez@tecmm.edu.mx',correo:'francisco.gutierrez@tecmm.edu.mx', telefono:'3481052705'},
        {nombre: 'C. Dayana Euridice Rameño Valadez',campus:'Chapala', linkCorreo:'mailto:dayana.rameno@chapala.tecmm.edu.mx',correo:'dayana.rameno@chapala.tecmm.edu.mx', telefono:'3319441289'},
        {nombre: 'Ing. Gilberto Lepe García',campus:'Cocula', linkCorreo:'mailto:gilberto.lepe@cocula.tecmm.edu.mx',correo:'gilberto.lepe@cocula.tecmm.edu.mx', telefono:'3751121753'},
        {nombre: 'MVZ. Jorge Rodríguez Martínez',campus:'El Grullo', linkCorreo:'mailto:jorge.rodriguez@elgrullo.edu.mx',correo:'jorge.rodriguez@elgrullo.edu.mx', telefono:'3211006309'},
        {nombre: 'Lic. Azucena Osorio Real',campus:'La Huerta', linkCorreo:'mailto:azucena.osorio@lahuerta.tecmm.edu.mx',correo:'azucena.osorio@lahuerta.tecmm.edu.mx', telefono:'3151100671'},
        {nombre: 'Lic. Juan Diego Ávila Ávila',campus:'Lagos de Moreno', linkCorreo:'mailto:diego.avila@lagos.tecmm.edu.mx',correo:'diego.avila@lagos.tecmm.edu.mx', telefono:'4741137472'},
        {nombre: 'Lic. María del Rocío Santana de Santiago',campus:'Mascota', linkCorreo:'mailto:vinculacion@mascota.tecmm.edu.mx',correo:'vinculacion@mascota.tecmm.edu.mx', telefono:'3222788703'},
        {nombre: 'Lic. Asdruval Rafael Ortíz Cuevas',campus:'Puerto Vallarta', linkCorreo:'mailto:asdruval.ortiz@vallarta.tecmm.edu.mx',correo:'asdruval.ortiz@vallarta.tecmm.edu.mx', telefono:'3221705736'},
        {nombre: 'Mtra. Iliana Navarro Tapia',campus:'Tala', linkCorreo:'mailto:iliana.navarro@tala.tecmm.edu.mx',correo:'iliana.navarro@tala.tecmm.edu.mx', telefono:'3317118205'},
        {nombre: 'Lic. Rogelio Ramírez Moreno',campus:'Tamazula', linkCorreo:'mailto:rogelio.ramirez@tamazula.tecmm.edu.mx',correo:'rogelio.ramirez@tamazula.tecmm.edu.mx', telefono:'3411348375'},
        {nombre: 'Lic. Luis Antonio Benítez Rubio',campus:'Tequila', linkCorreo:'mailto:luis.benitez@tequila.tecmm.edu.mx',correo:'luis.benitez@tequila.tecmm.edu.mx', telefono:'3312416888'},
        {nombre: 'Lic. Edgar Rodolfo Ruíz Becerra',campus:'Zapopan', linkCorreo:'mailto:edgar.ruiz@zapopan.tecmm.edu.mx',correo:'edgar.ruiz@zapopan.tecmm.edu.mx', telefono:'3315708870'},
        {nombre: 'Lic. Ricardo Navarro Rubio',campus:'Zapotlanejo', linkCorreo:'mailto:ricardo.navarro@zapotlanejo.tecmm.edu.mx',correo:'ricardo.navarro@zapotlanejo.tecmm.edu.mx', telefono:'8671760337'},

      ],
      contactos:false,
    }
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }


  show() {
    this.setState({ contactos: true });
  }

  hide() {
    this.setState({ contactos: false });
  }


  render() {

    return (

      <div className="div-principalPruebas">

      <Rodal customStyles={{overflowY:"scroll"}} width="900" height="600" visible={this.state.contactos} onClose={this.hide.bind(this)}>
        <div className="div-listaContactos">
          <div class="ui items">
            {this.state.contactosArray.map((it)=>(
              <div class="item">
                <div class="ui small image">
                  <img src="http://tecmm.edu.mx/imagesReact/images/directores/no-foto.jpg" />
                </div>
                <div class="middle aligned content">
                  <div class="header">
                    {it.nombre}
                  </div>
                  <div class="description">
                    <a>{it.campus}</a><br/>
                    <a target="_blank"href={it.linkCorreo}>{it.correo}</a><br/>
                    <a>{it.telefono}</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </Rodal>

      {this.props.isMobile() || this.props.isTablet()?(
        <div>
          <MenuCentralMovil/>
          <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          <img className="img-nombre" src={'http://tecmm.edu.mx/imagesReact/images/nombre-tecmm2.jpg'}/>
        </div>

        ) : (
        <div>
          <a href="/">
            <img className="img-bannerTNM" src="http://tecmm.edu.mx/imagesReact/images/header-nuevo2.jpg"/>
          </a>
          <MenuCentral/>
        </div>
      )}

        <div className="div-portadaVidaEstudiantil">
          <h1>
            <Typist> VIDA ESTUDIANTIL </Typist>
          </h1>
        </div>

        <div className="div-informacion-vidaEstudiantil">
          <div className="div-introduccion-vidaEstudiantil">
            <p className="p-introduccion">El Tecnológico Mario Molina en compromiso con la comunidad universitaria
            cuenta con espacios que permiten a los estudiantes desarrollar sus habilidades y aptitudes
            en las diferentes disciplinas del deporte, el arte y la cultura.</p>

            <p className="p-introduccion">Como parte de los programas que se imparten en la institución, el TECMM participa en distintas
            competencias tanto locales como nacionales, apoyando siempre el crecimiento de quienes conforman
            la comunidad estudiantil.</p>
          </div>

          <div id="deportesConuntos" className="div-disciplinas-vidaEstudiantil">
            <h1> DEPORTES CONJUNTOS</h1>

            <div class="ui items">
              {this.state.deportesArray.map((it)=>(
                <div class="item">
                  <div class="ui small image">
                    <img src={it.image}/>
                  </div>
                  <div class="middle aligned content">
                    <div class="header">
                      {it.nombre}
                    </div>
                    <div class="description">
                      <p></p>
                    </div>
                    <div class="extra">
                      <button onClick={this.show.bind(this)} class="ui right floated button">
                        Inscribete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

          <div id="deportesIndividuales" className="div-disciplinas-vidaEstudiantil">
            <h1> DEPORTES INDIVIDUALES</h1>

            <div class="ui items">
              {this.state.deportesIndividualesArray.map((it)=>(
                <div class="item">
                  <div class="ui small image">
                    <img src={it.image}/>
                  </div>
                  <div class="middle aligned content">
                    <div class="header">
                      {it.nombre}
                    </div>
                    <div class="description">
                      <p></p>
                    </div>
                    <div class="extra">
                      <div onClick={this.show.bind(this)} class="ui right floated button">
                        Inscribete
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>


          <div id="arteYCultura" className="div-disciplinas-vidaEstudiantil">
            <h1>ARTE Y CULTURA</h1>

            <div class="ui items">
              {this.state.arteYCulturaArray.map((it)=>(
                <div class="item">
                  <div class="ui small image">
                    <img src={it.image}/>
                  </div>
                  <div class="middle aligned content">
                    <div class="header">
                      {it.nombre}
                    </div>
                    <div class="description">
                      <p></p>
                    </div>
                    <div class="extra">
                      <div onClick={this.show.bind(this)} class="ui right floated button">
                        Inscribete
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>


          <div id="civicos" className="div-disciplinas-vidaEstudiantil">
            <h1>CÍVICOS</h1>

            <div class="ui items">
              {this.state.civicosArray.map((it)=>(
                <div class="item">
                  <div class="ui small image">
                    <img src={it.image}/>
                  </div>
                  <div class="middle aligned content">
                    <div class="header">
                      {it.nombre}
                    </div>
                    <div class="description">
                      <p></p>
                    </div>
                    <div class="extra">
                      <div onClick={this.show.bind(this)} class="ui right floated button">
                        Inscribete
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="div-competencias-vidaEstudiantil">
            <h1>JUEGOS INTERCAMPUS</h1>
            <div className="div-textoImagen-competencias">
              <p className="p-competencias">Donde participan los 13 Institutos Tecnológicos José Mario Molina Pasquel y Henríquez
              (Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Zapopan y  Zapotlanejo).
              Con las disciplinas académicas (Spelling Bee, Robótica, Programación) Culturales (Pintura, Oratoria, Fotografía y Música)
              Deportivas (Futbol , Basquetbol, Atletismo, Voleibol y Ajedrez), cívicos (Escoltas y Bandas de Guerra) y sociales (señorita Tec)</p>
              <img src="http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/intercampusMascota.jpeg"/>
            </div>
          </div>

          <div className="div-competencias-vidaEstudiantil">
            <h1>PRENACIONALES</h1>
            <div className="div-textoImagen-competencias">
              <p className="p-competencias">En este evento compiten los ganadores de cada disciplina de los
              juegos intercampus representando al TecMM ante los tecnológicos de la zona V,
              Con las disciplinas académicas (Spelling Bee, Robótica, Programación)
              Culturales (Pintura, Oratoria, Fotografía y Música) Deportivas (Futbol , Basquetbol, Atletismo, Voleibol y Ajedrez)
              cívicos (Escoltas y Bandas de Guerra) y sociales (señorita Tec).</p>

              <img src="http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/prenacioinales.jpg"/>
            </div>
          </div>

          <div className="div-competencias-vidaEstudiantil">
            <h1>FESTIVAL NACIONAL DE ARTE Y CULTURA (TECNOLÓGICO NACIONAL DE MÉXICO)</h1>
            <div className="div-textoImagen-competencias">
              <p className="p-competencias">Competencia donde participan los tecnológicos federales y descentralizados.
               Propósitos: fortalecer  el desarrollo intelectual y a sensibilidad de los estudiantes, con la práctica de actividades artísticas, reforzando valores y actitudes de identidad y compromiso.
               Incentivar el esfuerzo de los alumnos de los institutos tecnológicos del TecNM por sobresalir en la práctica de las diferentes actividades culturales.</p>

              <img src="http://tecmm.edu.mx/imagesReact/images/vidaEstudiantil/TECNM.png"/>
            </div>
          </div>


        </div>

      <LinksFooter/>

      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(VidaEstudiantil, options);
