import React, { Component } from 'react';
import {Route, HashRouter, BrowserRouter, Switch,Router} from 'react-router-dom'

import Landing from './landing.js';
import Campus from './components/campus.js';
import OfertaAcademica from './components/ofertaAcademica.js'
import OfertaAcademicaCampus from './components/ofertaAcademicaCampus.js'
import QuienesSomos from './components/quienesSomos.js'
import Rectoria from './components/rectoria.js'
import ModeloAcademico from './components/modeloAcademico.js'
import QuejasYDenuncias from './components/quejasYDenuncias.js'
import DenunciaOmisiones from './components/denunciaOmisiones.js'
import DenunciaAcoso from './components/denunciaAcoso.js'
import DenunciaContraloria from './components/denunciaContraloria.js'
import Building from './components/building.js'
import PatentesInvestigacion from './components/patentesInvestigacion.js'
import Valores from './components/valores.js'
import ComponentePruebas from './components/componentePruebas.js'
import Convocatorias from './components/convocatorias.js'
import Carrera from './components/carrera.js'
import Noticia from './components/noticia.js'
import Contacto from './components/contacto.js'
import VidaEstudiantil from './components/vidaEstudiantil.js'
import Igualdad from './components/igualdad.js'
import NoticieroComponent from './components/noticieroComponent.js'
import ContraloriaSocial from './components/contraloriaSocial.js'
import JuntasDeGobierno from './components/juntasDeGobierno.js'
import MovilidadInternacional from './components/movilidadInternacional.js'
import Eneit from './components/eneit.js'
import Festival from './components/festival.js'




class RouterPrincipal extends Component {
  render() {
    return (
      <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Landing}/>
          <Route path="/campus" component={Campus}/>
          <Route path="/ofertaAcademica" component={OfertaAcademica}/>
          {/*<Route path="/ofertaAcademica" component={OfertaAcademicaCampus}/>*/}
          <Route path="/quienesSomos" component={QuienesSomos}/>
          <Route path="/Rectoria" component={Rectoria}/>
          <Route path="/modeloAcademico" component={ModeloAcademico}/>
          <Route path="/quejasYdenuncias" component={QuejasYDenuncias}/>
          <Route path="/denunciaOmisiones" component={DenunciaOmisiones}/>
          <Route path="/denunciaAcoso" component={DenunciaAcoso}/>
          <Route path="/denunciaContraloria" component={DenunciaContraloria}/>
          <Route path="/building" component={Building}/>
          <Route path="/patentes" component={PatentesInvestigacion}/>
          <Route path="/Bienvenida" component={Valores}/>
          <Route path="/Convocatorias" component={Convocatorias}/>
          <Route path="/Pruebas" component={ComponentePruebas}/>
          <Route path="/Carrera/:carreraId" component={Carrera}/>
          <Route path="/Noticia/:noticiaNombre" component={Noticia}/>
          <Route path="/Contacto" component={Contacto}/>
          <Route path="/vidaEstudiantil" component={VidaEstudiantil}/>
          <Route path="/Igualdad" component={Igualdad}/>
          {/*<Route path="/Noticia" component={NoticieroComponent}/>*/}
          <Route path="/contraloriaSocial" component={ContraloriaSocial}/>
          <Route path="/juntasDeGobierno" component={JuntasDeGobierno}/>
          <Route path="/movilidadInternacional" component={MovilidadInternacional}/>
          <Route path="/eneit" component={Eneit}/>
          <Route path="/festival" component={Festival}/>


        </Switch>

      </BrowserRouter>


      </div>
    );
  }
}

export default RouterPrincipal;
