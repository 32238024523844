import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {withGetScreen} from 'react-getscreen'
import { Grid, Image } from 'semantic-ui-react'


import MenuCentral from './menuCentral.js'
import MenuCentralMovil from './menuCentralMovil'
import LinksFooter from './linksFooter.js'
import CardsComponent from './cardsComponent.js'

import './styles/ofertaAcademicaLanding.css'
import bannerTNM from '../header-nuevo2.jpg'

import Carreras from './carrerasData.js'



class CarreraItem extends Component{

  constructor(props){
    super(props);
    this.state={

    }

    this.clickImage = this.clickImage.bind(this);
  }

  clickImage(){

    var perfilEgresoLocal = this.props.perfil
    var perfilEgresoLocalString = JSON.stringify(perfilEgresoLocal)


    var cacheData = {portada:this.props.portada, youtube:this.props.youtube, campus:this.props.campus, objetivo:this.props.objetivo,
                     perfilEgreso:perfilEgresoLocalString, reticula:this.props.reticula, folleto:this.props.folleto}

    var cacheDataString = JSON.stringify(cacheData);

    localStorage.setItem("carrera", cacheDataString);

  }


  render(){

    const newTo = {
      pathname: "/Carrera/"+this.props.nombre,
    };


    return(

        <Link to={newTo}>
          <img onClick={this.clickImage} className="img-ItemCarreraLanding" src={this.props.card}/>
        </Link>

    );
  }
}




class OfertaAcademicaLanding extends Component{

    constructor(props){
      super(props);
      this.state={
        carrerasArray:null,
      }



    }

    componentWillMount(){

      var carrerasArrayVar = Carreras();

      localStorage.removeItem("carrera")

      this.setState({
         carrerasArray:carrerasArrayVar,
      })
    }




    render(){




    return(
      <div className="div-principal-ofertaAcademicaLanding">



        <div className="div-carrerasLanding">
        <h1>OFERTA ACADEMICA</h1>
          <Grid.Row centered columns={4}>
              {this.state.carrerasArray.map((it, key)=>(
                <CarreraItem keyIdenty={key} nombre={it.name} card={it.card} portada={it.portada} youtube={it.youtube} objetivo={it.objetivo} perfil={it.perfilEgreso} reticula={it.reticula} folleto={it.folleto} campus={it.campus}/>
              ))}
          </Grid.Row>
        </div>




      </div>
    );
  }
}


const options = {mobileLimit: 420, tabletLimit: 770}
export default withGetScreen(OfertaAcademicaLanding, options);
